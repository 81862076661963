import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SavedFilter } from "../../../services/filter.service";

@Component({
  selector: "app-saved-filter-list",
  templateUrl: "./saved-filter-list.component.html",
  styleUrl: "./saved-filter-list.component.scss",
})
export class SavedFilterListComponent {
  @Input() sidebarVisible: boolean = false;
  @Input() filters: SavedFilter[] = [];
  @Input() styleClass: string = "";
  @Input() setEdit: boolean = false;
  @Input() loading: boolean = false;

  @Output() applyFilter = new EventEmitter<any>();
  @Output() saveFilter = new EventEmitter<any>();
  @Output() editFilter = new EventEmitter<any>();
  @Output() deleteFilter = new EventEmitter<any>();

  edit = false;
  visible: boolean = false;
  toBeDeleted: SavedFilter | null = null;
  filterName: string = "";

  ngOnInit() {
    if (this.setEdit) this.edit = this.setEdit;
  }

  ngOnChanges(): void {
    if (!this.sidebarVisible) {
      this.filters = this.filters.filter((f) => f.id);
    }
  }

  onApplyFilter(filter: SavedFilter) {
    this.applyFilter.emit(filter);
  }

  handleRemovedChip(
    $event: { key: string; value: string },
    filter: SavedFilter
  ) {
    filter.filterValues = filter.filterValues.filter(
      (f) => f.key !== $event.key && f.value !== $event.value
    );
  }

  updateFilter(filter: SavedFilter) {
    this.filters = this.filters.map((f) => {
      if (f.id == filter.id) {
        return { ...filter };
      }
      return { ...f };
    });
  }

  onEdit(filter: SavedFilter) {
    this.filters = this.filters
      .map((f) => {
        if (f.id == filter.id) {
          return { ...f, isEditing: true };
        }
        return { ...f, isEditing: false };
      })
      .filter((f) => f.id);
    this.editFilter.emit(filter);
  }

  onSave(filter: SavedFilter) {
    this.filters = this.filters.map((f) => {
      return { ...f, isEditing: false };
    });
    this.saveFilter.emit(filter);
  }

  onDelete(filter: SavedFilter) {
    this.toBeDeleted = filter;
    this.visible = true;
  }

  onClose() {
    this.filters = this.filters.map((f) => {
      return { ...f, isEditing: false };
    });
  }

  public close() {
    this.onClose();
  }

  handleConfirmDelete() {
    this.filters = this.filters.filter((f) => f.id != this.toBeDeleted?.id);
    this.visible = false;
    this.deleteFilter.emit(this.toBeDeleted);
    this.toBeDeleted = null;
  }
  handleCancelDelete() {
    this.visible = false;
    this.toBeDeleted = null;
  }
}
