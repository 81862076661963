import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { StateService } from "./services/state.service";
import { NotificationService } from "./services/notification/notification.service";
import { decodeToken, getFormatedCountByStatus } from "./util/common.util";
import { Filter, FilterService } from "./services/filter.service";
import { INotification } from "./models/notification.model";
import { MessageService } from "primeng/api";
import { NotificationDetailComponent } from "./notification/components/notification-detail/notification-detail.component";

interface IPanel {
  label: string;
  value: string;
}
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent {
  constructor(
    private state: StateService,
    private notificationService: NotificationService,
    private filterService: FilterService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private route?: ActivatedRoute
  ) {}

  @ViewChild(NotificationDetailComponent)
  notificaitonDetailComponent!: NotificationDetailComponent;

  title(title: any) {
    throw new Error("Method not implemented.");
  }
  first: number = 0;
  rows: unknown;
  products: any[""];
  covidtest: any[""];
  sidebarVisible2: any;
  selectedTab: string = "all";
  sortBy: string = "";
  currentSort: string = "";
  panelSelectionPlaceholder: string = "Please select the panel";
  hasComments: boolean = false;
  hasUnread: boolean = false;
  appliedFilters: Filter[] = [];
  allEmailSubscribed: boolean = false;
  customEmailSubscribed: boolean = false;
  isLoadingSettings: boolean = false;
  isSavingSettings: boolean = false;
  isLoadingNotifications: boolean = false;

  // customEmailSubscriptionList: string[] = [];
  search: string = "";
  // onPageChange($event: PaginatorState) {
  //   this.first = $event.first as number;
  //   this.rows = $event.rows;
  // }

  // Custome Pagination attributes
  totalItems = 120;
  rowsPerPage = 10;
  currentPage = 1;
  notificationCountByStatus: any = {};

  // Handle the pagination change event
  onPageChange(event: any) {
    this.currentPage = event.currentPage;
    this.rowsPerPage = event.rowsPerPage;
    this.getNotificationsCall();
  }

  ////

  panels!: IPanel[];

  public selectedPanels: any[] = [];
  notifications: any[] = [];
  selectedNotification: any;
  totalNotifications: number = 0;
  notificationsinList: number = 0;
  appliedFilterCount: number = 0;
  customEmailOptions: any[] = [];

  onPanelChange(event: any) {
    if (event.value.length > 1) {
      this.panelSelectionPlaceholder = "Multiple Panels Selected";
    }
    this.currentPage = 1;
    this.getNotificationsCall();
  }
  ngOnInit() {
    this.covidtest = [
      {
        test: "COVID19 Symptoms",
        date: "08-31-2024 9:10 AM",
        source: "St. Bermardine Medical Center (Dignity)",
      },
      {
        test: "COVID19 Symptoms",
        date: "08-31-2024 9:10 AM",
        source: "St. Bermardine Medical Center (Dignity)",
      },
    ];

    window.addEventListener("message", this.handleMessage.bind(this));

    // subscriptions
    // this.notificationService.getCSRFToken().subscribe((token) => {
    //   this.state.setCSRFToken(token.csrfToken);
    // });

    // route subscription
    this.route?.queryParams.subscribe((params: Params) => {
      const token = params["token"];
      this.state.setUser({
        ...this.state.getUser(),
        email: decodeToken(token)?.username,
      });
      this.panels =
        decodeToken(token)
          ?.["cognito:groups"]?.filter((group: string | string[]) =>
            group.includes("panel-")
          )
          .map((group: string) => ({
            label: group.replace("panel-", "").replaceAll("_", " "),
            value: group.replace("panel-", ""),
          })) || [];
      const region =
        params["region"] && params["region"] !== "null"
          ? params["region"]
          : "us-west-2";
      const userPoolId =
        params["userPoolId"] && params["userPoolId"] !== "null"
          ? params["userPoolId"]
          : "us-west-2_knNpxaxWq";
      const org =
        params["org"] && params["org"] !== "null" ? params["org"] : "mx-notify-test";
      const uuxSessionId =
        params["uuxSessionId"] && params["uuxSessionId"] !== "null"
          ? params["uuxSessionId"]
          : 1234;
      if (token) {
        this.state.setAccessToken(token);
        this.state.setMXRequestHeaderFields({
          region,
          "uux-session-id": uuxSessionId,
          organization: org,
          pool_id: userPoolId,
        });
        const body = {
          pageSize: 10,
          pageNo: 1,
          status: "all",
        } as any;
        this.isLoadingNotifications = true;
        this.notificationService.getNotifications(body).subscribe({
          next: (notifications) => {
            if (notifications?.data?.length > 0) {
              this.state.setNotifications(notifications.data);
              this.state.setNotificationCountByStatus(
                getFormatedCountByStatus(notifications.metaData)
              );
              this.notificationCountByStatus =
                this.state.getNotificationCountByStatus();
              this.totalNotifications =
                notifications?.metaData?.totalFilteredCount || 0;
              this.notificationsinList = notifications?.data?.length || 0;
              this.notifications = notifications.data;
              this.isLoadingNotifications = false;
            }
          },
          error: (err) => {
            console.error("Error while subscribing:", err);
            this.isLoadingNotifications = false;
          },
        });

        this.filterService.getAllSavedFilters().subscribe({
          next: (data) => {
            this.state.setSavedFilters(data); // Assigns the fetched filters to a component variable
            this.customEmailOptions = data.map((filter) => ({
              label: filter.name,
              value: filter.id,
            }));
            this.getEmailSettingsCall();
          },
          error: (err) => {
            console.error("Error while subscribing:", err); // Handles any additional errors that may occur
          },
        });
      }
    });
  }
  getEmailSettingsCall() {
    this.isLoadingSettings = true;
    this.notificationService.getUserSettings().subscribe({
      next: (res) => {
        this.allEmailSubscribed = res.emails_enabled;
        this.customEmailSubscribed = res.is_customized;
        this.customEmailOptions = this.state
          .getSavedFilters()
          ?.map((option) => {
            if (
              res.setting_filters?.find(
                (setting: { filter: { id: any } }) =>
                  setting.filter?.id === option.id
              )
            ) {
              return { label: option.name, value: option.id, checked: true };
            }
            return { label: option.name, value: option.id, checked: false };
          });
        this.isLoadingSettings = false;
        this.cdr.detectChanges();
      },
      error: (err) => {
        console.error("Error while subscribing:", err);
        this.isLoadingSettings = false;
      },
    });
  }
  onSearchChange() {
    this.currentPage = 1;
    this.getNotificationsCall();
  }
  onChangeEmailSettings(event: any) {
    if (event.target.id === "allEmailSubscribed") {
      this.allEmailSubscribed = event.target.checked;
      this.customEmailSubscribed = false;
    } else {
      this.allEmailSubscribed = false;
      this.customEmailSubscribed = event.target.checked;
    }
    this.customEmailOptions = this.customEmailOptions.map((option) => ({
      ...option,
      checked: false,
    }));
  }
  onChangeCustomEmailSettings(event: any) {
    this.customEmailOptions = this.state.getSavedFilters().map((filter) => {
      const foundEvent = event.find((e: any) => e.value === filter.id);
      if (foundEvent) {
        return {
          label: filter.name,
          value: filter.id,
          checked: foundEvent.checked,
        };
      }
      return { label: filter.name, value: filter.id, checked: false };
    });
  }
  onSaveEmailSettings() {
    const body = {
      emails_enabled: this.allEmailSubscribed || false,
      is_customized: this.customEmailSubscribed || false,
      filter_ids: this.customEmailOptions
        ?.map((option) => {
          if (option.checked) {
            return option.value;
          }
        })
        .filter((option) => option),
    };
    if (body.is_customized && body.filter_ids.length === 0) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Please select atleast one filter",
      });
      return;
    }
    this.isSavingSettings = true;
    this.notificationService.updateUserSettings(body).subscribe({
      next: (res) => {
        this.getEmailSettingsCall();
        this.sidebarVisible2 = false;
        this.isSavingSettings = false;
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Email settings updated",
        });
      },
      error: (err) => {
        console.error("Error while subscribing:", err);
        this.isLoadingSettings = false;
      },
    });
  }
  onCloseEmailSettings() {
    this.customEmailOptions = this.state.getSavedFilters().map((filter) => ({
      label: filter.name,
      value: filter.id,
    }));
    this.getEmailSettingsCall();
    this.sidebarVisible2 = false;
  }
  onTabChangeHandler(selectedTab: any) {
    this.currentPage = 1;
    this.selectedTab = selectedTab;
    this.getNotificationsCall();
  }
  onSortHandler(sort: string) {
    this.currentPage = 1;
    if (this.currentSort === sort) {
      this.sortBy = "";
      this.hasComments = false;
      this.hasUnread = false;
      this.currentSort = "";
      this.getNotificationsCall();
      return;
    }
    this.currentSort = sort;

    switch (sort) {
      case "Newest":
      case "Oldest":
        this.sortBy = sort;
        this.hasComments = false;
        this.hasUnread = false;
        break;
      case "comments":
        this.sortBy = "";
        this.hasComments = true;
        this.hasUnread = false;
        break;
      case "unread":
        this.sortBy = "";
        this.hasComments = false;
        this.hasUnread = true;
        break;
      default:
        this.sortBy = "";
        this.hasComments = false;
        this.hasUnread = false;
        break;
    }
    this.getNotificationsCall();
  }
  getNotificationsCall() {
    const body = {
      pageSize: this.rowsPerPage,
      pageNo: this.currentPage,
      status: this.selectedTab,
    } as any;
    if (this.sortBy) {
      body.sort = this.sortBy === "Newest" ? "DESC" : "ASC";
    } else if (this.hasComments) {
      body.onlyWithComments = this.hasComments;
    } else if (this.hasUnread) {
      body.onlyUnRead = this.hasUnread;
    }
    if (this.appliedFilters?.length > 0) {
      body.filters = this.appliedFilters;
    }
    if (this.selectedPanels?.length > 0) {
      body.panels = this.selectedPanels.map((panel) =>
        panel.replace("panel-", "")
      );
    }
    if (this.search) {
      body.search = this.search;
    }
    this.isLoadingNotifications = true;
    this.notificationService.getNotifications(body).subscribe({
      next: (notifications) => {
        this.state.setNotifications(notifications.data);
        this.state.setNotificationCountByStatus(
          getFormatedCountByStatus(notifications.metaData)
        );
        this.notificationCountByStatus =
          this.state.getNotificationCountByStatus();
        this.totalNotifications =
          notifications?.metaData?.totalFilteredCount || 0;
        this.notificationsinList = notifications?.data?.length || 0;
        this.notifications = notifications.data;
        this.isLoadingNotifications = false;
      },
      error: (err) => {
        console.error("Error while subscribing:", err);
        this.isLoadingNotifications = false;
      },
    });
  }

  setSelectedNotification(index: any) {
    this.selectedNotification = this.notifications[index];
  }

  filtersVisible = false;
  openFilters() {
    this.filtersVisible = true;
  }
  closeFilters() {
    this.filtersVisible = false;
  }
  openSettingsDrawer() {
    this.isLoadingSettings = true;
    this.filterService.getAllSavedFilters().subscribe({
      next: (data) => {
        this.state.setSavedFilters(data);
        this.customEmailOptions = data.map((filter) => ({
          label: filter.name,
          value: filter.id,
        }));
        this.getEmailSettingsCall();
      },
      error: (err) => {
        console.error("Error while subscribing:", err);
      },
    });
    this.sidebarVisible2 = true;
  }
  handleAppliedFilters(appliedFilterCount: number) {
    this.appliedFilterCount = appliedFilterCount;
  }

  onNotificationSelect(notification: INotification) {
    this.selectedNotification = notification;
  }
  handleMessage(event: MessageEvent) {
    if (
      event.origin.includes("http://localhost:4200") ||
      event.origin.includes("https://mx-dev-wrapper.stella-apps.com")
    ) {
      const token = event.data.token;
      if (token) {
        this.state.setAccessToken(token);
      }
    }
  }
  selectedCategories: any[] = [];

  categories: any[] = [
    { name: "Accounting", key: "A" },
    { name: "Marketing", key: "M" },
    { name: "Production", key: "P" },
    { name: "Research", key: "R" },
  ];
  notificationDetail: any = {};

  handleApplyFilters(filters: Filter[]) {
    this.currentPage = 1;
    this.appliedFilters = filters;
    this.appliedFilterCount = filters.length;
    this.getNotificationsCall();
  }
  scrollToComments() {
    this.notificaitonDetailComponent.scrollToComments();
  }
  handleStatusChange(event: {
    notificationId: string;
    status: string;
    previousStatus: string;
  }) {
    this.notifications = [
      ...this.notifications?.map((notification) => {
        if (notification.id === event.notificationId) {
          return { ...notification, status: event.status };
        } else {
          return { ...notification };
        }
      }),
    ];
    this.notificationCountByStatus[event.status] =
      this.notificationCountByStatus[event.status] + 1;
    this.notificationCountByStatus[event.previousStatus] =
      this.notificationCountByStatus[event.previousStatus] - 1;
  }
}
