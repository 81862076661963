<div
  *ngIf="!loading && !isEmpty; else loadingBlock"
  class="bg-white w-full border-1 border-round border-gray-300"
>
  <div
    class="mxn-notify-detailHeader flex justify-content-between align-items-center"
  >
    <div class="flex justify-content-between align-items-center gap-2">
      <div class="mxn-notify-pImg">
        <img src="/assets/profile-placeholder.svg" alt="" />
      </div>
      <div class="mxn-notify-pname">{{ notification.patientName }}</div>
      <div class="mxn-notify-extInfo">
        ({{ notification.age }} yrs) {{ notification.gender }}
      </div>
    </div>
    <div
      class="mxn-overlay mxn-notify-statusBadge"
      [ngClass]="{
        discharge: notification.statusBadge === 'completed',
        inprogress: notification.statusBadge === 'not-started',
        notstarted: notification.statusBadge === 'in-progress'
      }"
    >
      <img [src]="statusBadgeIconMap.get(notification.statusBadge)" alt="" />
      {{ notification.statusBadge | keyFormatter }}
      <div class="mxn-overlay-ddm">
        <div class="mxn-overlay-items">
          <ul>
            <li (click)="onStatusChange(notification.id, 'completed')">
              <span class="mxn-sortby-icon"
                ><img src="/assets/check.svg" alt="Completed" /></span
              >Completed
            </li>
            <li (click)="onStatusChange(notification.id, 'not-started')">
              <span class="mxn-sortby-icon"
                ><img src="/assets/hovered.svg" alt="Not Started" /></span
              >Not Started
            </li>
            <li (click)="onStatusChange(notification.id, 'in-progress')">
              <span class="mxn-sortby-icon"
                ><img src="/assets/inprogress.svg" alt="In Progress" /></span
              >In Progress
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <mxn-detail-panel [contentTemplate]="demographicsDetail"></mxn-detail-panel>
  <ng-template #demographicsDetail>
    <mxn-detail-info [details]="notification.demographics"></mxn-detail-info>
  </ng-template>

  <div class="mxn-detail-panel">
    <h3 class="mxn-panel-heading">Organization Panels</h3>
    <div class="mxn-inner-panel">
      <div class="flex justify-content-between gap-4">
        <div class="col-12 flex flex-column gap-2 py-0">
          <ul class="pl-2 m-0">
            <li
              *ngFor="let panel of notification.organisationPanels"
              class="mxn-td-info py-1"
            >
              {{ panel.panelName }}
              <span class="mxn-td-label font-normal">MRN:</span>
              {{ panel.mrn }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="notification?.laboratoryResults?.length > 0">
    <mxn-detail-panel
      heading="Laboratory Result"
      [contentTemplate]="laboratoryTable"
      class="max-nobordered-panel"
    ></mxn-detail-panel>
  </ng-container>

  <ng-template #laboratoryTable>
    <mxn-table
      [columns]="laboratoryColumns"
      [data]="notification.laboratoryResults"
      [headerClasses]="labHeaderClasses"
      [bodyClasses]="labBodyClasses"
      [nestedColumns]="laboratoryNestedColumns"
      [nestedHeaderClasses]="nestedLabHeaderClasses"
      [nestedBodyClasses]="nestedLabBodyClasses"
    >
    </mxn-table>
  </ng-template>

  <div class="mxn-detail-panel" *ngIf="notification?.products?.length > 0">
    <h3 class="mxn-panel-heading">Encounter</h3>
    <div class="mxn-inner-panel p-0">
      <div class="p-3">
        <mxn-detail-info [details]="notification.encounter"></mxn-detail-info>
      </div>
      <h3 class="mxn-panel-heading border-top-1 border-gray-300 pt-3 pl-4">
        Diagnosis Details
      </h3>
      <div class="max-fulltable">
        <mxn-table
          [columns]="diagnosisColumns"
          [data]="notification.products"
          [headerClasses]="diagnosisHeaderClasses"
          [bodyClasses]="diagnosisBodyClasses"
        >
        </mxn-table>
      </div>
    </div>
  </div>

  <div class="mxn-detail-panel" #commentsSection>
    <h3 class="mxn-panel-heading">Comments</h3>
    <div class="mxn-inner-panel p-3 pr-0">
      <mxn-comment-card
        *ngFor="let comment of notification.comments"
        [comment]="comment"
        (onClickEditComment)="onEditComment(comment)"
        (onClickDeleteComment)="onDeleteComment(comment)"
        [notificationId]="notification?.id"
        [startedEditing]="
          editingCommentId !== '' && comment?.id !== editingCommentId
        "
        (toggleEditComment)="toggleEditComment(comment)"
        (refreshNotificationDetail)="getNotificationDetails()"
      ></mxn-comment-card>

      <div class="pr-3">
        <div class="mxn-notify-newcomment mt-3">
          <p-inputGroup class="">
            <input
              type="text"
              class="pInputText"
              placeholder="Type Comment here...."
              [(ngModel)]="newComment"
            />
            <button
              type="button"
              class="pButton btn-sm"
              (click)="handleAddComment()"
              [disabled]="isSavingNewComment"
            >
              Add Comment
            </button>
          </p-inputGroup>
        </div>
      </div>
    </div>
  </div>

  <mxn-detail-panel
    *ngIf="notification?.products?.length > 0"
    heading="Notification Audit"
    [contentTemplate]="demographicsTable"
    class="max-nobordered-panel"
  ></mxn-detail-panel>
  <ng-template #demographicsTable>
    <mxn-table
      *ngIf="notification?.products?.length > 0"
      [columns]="demographicsColumns"
      [data]="notification.products"
      [headerClasses]="demographicsHeaderClasses"
      [bodyClasses]="demographicsBodyClasses"
    >
    </mxn-table>
  </ng-template>
</div>
<ng-template #loadingBlock>
  <div *ngIf="loading && !isEmpty; else emptyBlock" class="loader">
    <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
  </div>
</ng-template>

<ng-template #emptyBlock>
  <div class="mxn-noNotify-select">
    <img src="/assets/notificatin-details-icn.svg" alt="Select a notification">
    <p>Select a notification from the list to see full details.</p>
  </div>
</ng-template>
