<div class="mxn-notify-accordion">
  <p-accordion
    *ngIf="!loading; else loadingBlock"
    class="w-full"
    [multiple]="true"
  >
    <p-accordionTab
      *ngFor="let notification of notifications"
      [tabStyleClass]="getCardStyleClasses(notification.status)"
      (click)="onNotificationClick(notification.id)"
    >
      <ng-template pTemplate="header">
        <div class="w-full">
          <div class="flex justify-content-between align-items-center mb-2">
            <div
              class=""
              [ngClass]="{
                'mxn-notify-title': true,
                'font-semibold': notification.notification_reads.length > 0
              }"
            >
              {{ getADTLabel(notification.event_type) }}
            </div>
            <div class="mxn-notify-act" (click)="$event.stopPropagation()">
              <div class="mxn-notify-commints-tooltip mxn-overlay">
                <img
                  (click)="scrollToCommentsHandler()"
                  src="/assets/chat-text.svg"
                  alt="Comments"
                />
                <div
                  class="mxn-overlay-ddm"
                  *ngIf="notification.comments.length > 0"
                >
                  <div class="mxn-overlay-items">
                    <ul>
                      <li *ngFor="let comment of notification.comments">
                        <span class="mxn-sortby-icon"
                          ><img src="/assets/exclamation.svg" alt="exclamation"
                        /></span>
                        <p class="p-0 m-0">
                          {{ comment.text }}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="mxn-overlay">
                <img
                  *ngIf="!(notification.notification_reads.length > 0)"
                  src="/assets/notify-unread.svg"
                  alt=""
                />
                <img
                  *ngIf="notification.notification_reads.length > 0"
                  src="/assets/notify-read.svg"
                  alt=""
                />
                <!-- <div class="mxn-overlay-ddm">
                  <div class="mxn-overlay-items w-8rem">
                    <ul>
                      <li
                        *ngIf="!(notification.notification_reads.length > 0)"
                        class="text-sm"
                      >
                        Make as read
                      </li>
                      <li
                        *ngIf="notification.notification_reads.length > 0"
                        class="text-sm"
                      >
                        Make as Unread
                      </li>
                    </ul>
                  </div>
                </div> -->
              </div>
              <div class="mxn-overlay mxn-status-tooltip">
                <img
                  *ngIf="notification.status === 'not-started'"
                  src="/assets/hovered.svg"
                  alt="Not Started"
                />
                <img
                  *ngIf="notification.status === 'in-progress'"
                  src="/assets/inprogress.svg"
                  alt="In Progress"
                />
                <img
                  *ngIf="notification.status === 'completed'"
                  src="/assets/check.svg"
                  alt="Completed"
                />
                <div class="mxn-overlay-ddm">
                  <div class="mxn-overlay-items w-8rem">
                    <ul>
                      <li
                        [ngClass]="{
                          active: notification.status === 'not-started'
                        }"
                        (click)="
                          notification.status !== 'not-started' &&
                            updateNotificationStatusCall(
                              notification.id,
                              'not-started'
                            )
                        "
                      >
                        <span class="mxn-sortby-icon"
                          ><img
                            src="/assets/hovered.svg"
                            alt="Not Started" /></span
                        >Not Started
                      </li>
                      <li
                        [ngClass]="{
                          active: notification.status === 'in-progress'
                        }"
                        (click)="
                          notification.status !== 'in-progress' &&
                            updateNotificationStatusCall(
                              notification.id,
                              'in-progress'
                            )
                        "
                      >
                        <span class="mxn-sortby-icon"
                          ><img
                            src="/assets/inprogress.svg"
                            alt="In Progress" /></span
                        >In Progress
                      </li>
                      <li
                        [ngClass]="{
                          active: notification.status === 'completed'
                        }"
                        (click)="
                          notification.status !== 'completed' &&
                            updateNotificationStatusCall(
                              notification.id,
                              'completed'
                            )
                        "
                      >
                        <span class="mxn-sortby-icon"
                          ><img src="/assets/check.svg" alt="Completed" /></span
                        >Completed
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-content-between align-items-center mb-1">
            <div class="flex gap-2">
              <div class="mxn-notify-Pname">
                {{ notification.encounter.name }}
              </div>
              <div class="mxn-notify-Pgen">
                [<span>{{ notification.encounter.gender }}</span
                >]
              </div>
              <div class="mxn-notify-Pdob">
                DOB:
                {{ notification.encounter.birth_date | date : "MM-dd-yyyy" }}
              </div>
            </div>
            <div class="acc-tab-icon">
              <i class="pi pi-angle-down"></i>
            </div>
          </div>
          <div
            class="flex justify-content-between align-items-center mxn-notify-provider"
          >
            *Clovis Community Medical Center (CMC)
          </div>
        </div>
      </ng-template>
      <div class="">
        <div class="flex justify-content-between align-items-center mb-10">
          <div class="mxn-notify-Dlabel">
            <div class="mxn-notify-Ricon">
              <img src="./assets/date-icon.svg" alt="Date" />
            </div>
            <div>Event Date</div>
          </div>
          <div class="mxn-notify-Dinfo">
            {{ notification.event_date | date : "MM-dd-yyyy hh:mm a" }}
          </div>
        </div>
        <div class="flex justify-content-between align-items-center mb-10">
          <div class="mxn-notify-Dlabel">
            <div class="mxn-notify-Ricon">
              <img src="./assets/panel.svg" alt="Panel" />
            </div>
            <div>Panel</div>
          </div>
          <div class="mxn-notify-Dinfo">
            {{ getPanelNames(notification).slice(0, 3).toString() || "-" }}
            <span
              *ngIf="getPanelNames(notification).slice(3).length > 0"
              class="mxn-notify-bubbleSolid"
              >+{{ getPanelNames(notification).slice(3).length }}</span
            >
          </div>
        </div>
        <div class="flex justify-content-between align-items-center mb-10">
          <div class="mxn-notify-Dlabel">
            <div class="mxn-notify-Ricon">
              <img src="./assets/ambulance.svg" alt="Admit" />
            </div>
            <div>Admit Reason</div>
          </div>
          <div class="mxn-notify-Dinfo">
            {{ notification.encounter.admit_reason }}
          </div>
        </div>
        <div class="flex justify-content-between align-items-center mb-10">
          <div class="mxn-notify-Dlabel">
            <div class="mxn-notify-Ricon">
              <img src="./assets/calendar.svg" alt="Encounter" />
            </div>
            <div>Dates of Encounter</div>
          </div>
          <div class="mxn-notify-Dinfo">*8/29/2024 5:26:00 AM</div>
        </div>
      </div>
    </p-accordionTab>
  </p-accordion>
  <ng-template #loadingBlock>
    <div class="loader">
      <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
    </div>
  </ng-template>
</div>
