<p-dropdown
  [id]="id"
  [ngClass]="styleClass"
  [dropdownIcon]="dropdownIcon"
  [options]="items"
  [(ngModel)]="selectedItem"
  [optionLabel]="optionLabel"
  placeholder="{{ placeholder }}"
  [style]="{ width: width }"
  [filter]="filter"
  [showClear]="showClear"
  [editable]="editable"
  [disabled]="disabled"
  (onChange)="onSelectionChange()"
>
</p-dropdown>
