import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-combo-box",
  templateUrl: "./combo-box.component.html",
  styleUrls: ["./combo-box.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboBoxComponent),
      multi: true
    }
  ]
})
export class ComboBoxComponent implements ControlValueAccessor {
  @Input() id: any = null;
  @Input() items: any[] = [];
  @Input() optionLabel: string = "name";
  @Input() placeholder: string = "Select an item";
  @Input() width: string = "200px";
  @Input() filter: boolean = false;
  @Input() showClear: boolean = false;
  @Input() editable: boolean = false;
  @Input() disabled: boolean = false;
  @Input() dropdownIcon: string = "";
  @Input() styleClass: string = "";
  @Input() showSelected: boolean = true;

  @Output() selectionChange = new EventEmitter<any>();
  selectedItem: any = null;

  // These are for ControlValueAccessor
  onChange: any = () => {};
  onTouch: any = () => {};

  // Called when the value in the form is updated
  writeValue(value: any): void {
    if (value) {
      this.selectedItem = value;
    } else {
      this.selectedItem = "";
    }
  }

  // Register the function that should be called when the control's value changes in the UI
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Register the function that should be called when the control receives a touch event
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  // This is called when the disabled status changes
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onSelectionChange() {
    this.selectionChange.emit(this.selectedItem);
    if (!this.showSelected) {
      setTimeout(() => {
        this.selectedItem = "";
      }, 50);
    }
  }
}
