import { Injectable } from "@angular/core";
import { catchError, map, Observable } from "rxjs";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { HttpErrorHandlerService } from "./http-error-handler.service";
import { StateService } from "./state.service";

@Injectable({
  providedIn: "root",
})
export class ServiceClientService {
  private defaultHeaders = new HttpHeaders({
    "Content-Type": "application/json",
    Platform: "web",
  });

  constructor(
    private http: HttpClient,
    private httpErrorHandlerService: HttpErrorHandlerService,
    private notificationState: StateService
  ) {}

  get<TResponse>(
    endPoint: string,
    params?: HttpParams
  ): Observable<TResponse | any> {
    const headers = this.createHeader();
    return this.http
      .get<TResponse>(endPoint, { headers: headers, params: params })
      .pipe(
        map((resp: any) => resp),
        catchError(async (err) => this.httpErrorHandlerService.handleError(err))
      );
  }

  post<TRequest, TResponse>(
    endPoint: string,
    request: TRequest
  ): Observable<TResponse | any> {
    const headers = this.createHeader();

    return this.http
      .post<TResponse>(endPoint, request, { headers: headers })
      .pipe(
        map((resp) => resp),
        catchError(async (err) => this.httpErrorHandlerService.handleError(err))
      );
  }

  put<TRequest, TResponse>(
    endPoint: string,
    request: TRequest
  ): Observable<TResponse | any> {
    const headers = this.createHeader();
    return this.http
      .put<TResponse>(endPoint, request, { headers: headers })
      .pipe(
        map((resp) => resp),
        catchError(async (err) => this.httpErrorHandlerService.handleError(err))
      );
  }

  patch<TRequest, TResponse>(
    endPoint: string,
    request: TRequest
  ): Observable<TResponse | any> {
    const headers = this.createHeader();

    return this.http
      .patch<TResponse>(endPoint, request, { headers: headers })
      .pipe(
        map((resp) => resp),
        catchError(async (err) => this.httpErrorHandlerService.handleError(err))
      );
  }

  delete<TResponse>(
    endPoint: string
  ): Observable<TResponse | HttpErrorResponse> {
    const headers = this.createHeader();
    return this.http.delete<TResponse>(endPoint, { headers: headers }).pipe(
      map((resp: any) => resp),
      catchError(async (err) => this.httpErrorHandlerService.handleError(err))
    );
  }

  createHeader() {
    return {
      "Content-Type": "application/json",
      "x-csrf-token": this.notificationState.getCSRFToken(),
      Authorization: this.notificationState.getAccessToken(),
      ...this.notificationState.getMXRequestHeaderFields(),
    };
  }
}
