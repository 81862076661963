import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateRangeSelectionComponent } from './date-range-selection/date-range-selection.component';
import { ComboBoxComponent } from './combo-box/combo-box.component';
import { SearchableComboBoxComponent } from './searchable-combo-box/searchable-combo-box.component';
import { ChekboxIconComponent } from './chekbox-icon/chekbox-icon.component';
import { ButtonDropdownComponent } from './button-dropdown/button-dropdown.component';
import { CollapsableComponent } from './collapsable/collapsable.component';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { SavedFilterListComponent } from './saved-filter-list/saved-filter-list.component';
import { AtomModule } from "../atom/atom.module";
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';



@NgModule({
  declarations: [
    DateRangeSelectionComponent,
    ComboBoxComponent,
    SearchableComboBoxComponent,
    ChekboxIconComponent,
    ButtonDropdownComponent,
    CollapsableComponent,
    SavedFilterListComponent
  ],
  imports: [
    CommonModule,
    CheckboxModule,
    FormsModule,
    DropdownModule,
    AtomModule,
    DialogModule,
    ButtonModule,
    ProgressSpinnerModule
],
  exports:[
    ComboBoxComponent,
    ChekboxIconComponent,
    SavedFilterListComponent
  ]
})
export class MoleculeModule { }
