import { endpoints } from "./endpoints";
import { EnvironmentModel, MXURLS } from "./environment.modal";

export const MX: MXURLS = {
  notificationServiceBaseURL:
    "https://notify-stage-gateway.manifestmedex.org",
};

export const environment: EnvironmentModel = {
  production: false,
  endPoints: endpoints,
};
