<div class="flex justify-content-between align-items-center">
  <div>
    <!-- <select
      class="mxn-showRows"
      [ngModel]="rowsPerPage"
      (change)="changeRowsPerPage($event)"
    >
      <option
        class="option"
        *ngFor="let option of rowsOptions"
        [value]="option"
      >
        {{ option }} Rows
      </option>
    </select> -->
    <p-dropdown
      class="mxn-showRows"
      [options]="rowsOptions"
      [(ngModel)]="rowsPerPage"
      optionLabel="label"
      placeholder="{{ rowsPerPage.toString() }}"
      (onChange)="changeRowsPerPage($event)"
    >
    </p-dropdown>
  </div>
  <div class="pagination">
    <button
      (click)="changePage(currentPage - 1)"
      [disabled]="currentPage === 1"
      class="pagination-button"
    >
      <i class="pi pi-arrow-left"></i> Previous
    </button>

    <button
      *ngFor="let page of paginationArray"
      (click)="changePage(page)"
      [ngClass]="{
        'pagination-button-disabled': page === '...',
        'pagination-button': page !== '...',
        active: isActive(page)
      }"
      [disabled]="page === '...'"
    >
      {{ page }}
    </button>

    <button
      (click)="changePage(currentPage + 1)"
      [disabled]="currentPage === totalPages || totalPages === 0"
      class="pagination-button"
    >
      Next <i class="pi pi-arrow-right"></i>
    </button>
  </div>
</div>
