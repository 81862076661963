export const filterKeys = {
  EVENT_DATE: "event_date",
  GENDER: "gender",
  AGE: "age",
  PATIENT_CLASS: "patient_class",
  EVENT: "event",
  FACILITY: "facility",
  // Advanced:
  ADMIT_REASON: "admit_reason",
  DX_CODES: "dx_codes",
  PCB_NAME: "pcb_name",
  DISCHARGE_DISPOSITION: "discharge_disposition",
};

export const operatorList = [
  { name: "Less Than", operation: "LESS_THAN" },
  { name: "Greater Than", operation: "GREATER_THAN" },
  { name: "Equal To", operation: "EQUAL" },
];

export enum OPERATORS_SIGNS {
  BETWEEN = "BETWEEN",
  LESS_THAN = "<",
  GREATER_THAN = ">",
  EQUAL_TO = "=",
  NOT_EQUAL = "!=",
}
