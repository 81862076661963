import { IComment } from "../models/comment.model";
import { IUser } from "../models/user.model";
import { toCamelCase } from "../util/common.util";

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
};

const formatDateTime = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };
  const formattedDate = date.toLocaleString('en-US', options);
  return formattedDate.replace(/\//g, '-').replace(/,/g, '');
};

const patientClassMap = new Map([["E", "Emergency"]]);

export const notificationMock = (notification: any, currentUser: IUser) => {
  //   {
  //     "id": "43012339-41a0-481d-a259-46c0cf31c848",
  //     "created_at": "2024-09-30T09:48:12.845Z",
  //     "updated_at": "2024-09-30T09:48:12.845Z",
  //     "source_uid": "MX Source id",
  //     "source_patient_identifier": "source_patient_identifier",
  //     "source_event_id": "source_event_id",
  //     "other_fields": {
  //         "display_name": "Display name"
  //     },
  //     "search_optimized_field": "search_optimized_field",
  //     "status": "not-started",
  //     "event_type": "event_type",
  //     "initial_message_at": "2024-09-30T09:48:12.845Z",
  //     "last_message_at": "2024-09-30T09:48:12.845Z",
  //     "organization": {
  //         "id": "3edc009f-40f7-478d-9556-2c820d90a706",
  //         "name": "MX notification",
  //         "pool_id": "pool_id"
  //     },
  //     "panels": [
  //         {
  //             "id": "7b33113f-2179-4420-b7e1-353d4aafe26c",
  //             "created_at": "2024-09-30T09:48:12.845Z",
  //             "updated_at": "2024-09-30T09:48:12.845Z",
  //             "panel": "panel",
  //             "display_name": "Display name",
  //             "pane": "pane"
  //         }
  //     ],
  //     "patient_meta": {
  //         "id": "c927faec-df9e-4190-a291-e53c51e80c7a",
  //         "created_at": "2024-09-30T09:48:12.845Z",
  //         "updated_at": "2024-09-30T09:48:12.845Z",
  //         "mpi_id": "mpi_id",
  //         "field": "MX Field 1 ",
  //         "laber": "laber",
  //         "value": "MX field value",
  //         "event": "event"
  //     },
  //     "notification_read_status": true
  // }
  return {
    id: notification?.id,

    patientName:
      `${notification?.encounter?.json?.encounter?.patient?.name?.given[0]} ${notification?.encounter?.json?.encounter?.patient?.name?.family}` ||
      "-",

    age:
      new Date().getFullYear() -
      new Date(notification?.encounter?.birth_date).getFullYear(),

    gender: notification?.encounter?.gender,

    statusBadge:
      notification?.status && !notification?.error
        ? notification?.status
        : "not-started",

    demographics: [
      { label: "DOB", value: formatDate(notification?.encounter?.birth_date) },
      {
        label: "Home Phone",
        value:
          notification?.encounter?.json?.encounter?.patient?.telecom?.[0]
            ?.value,
      },
      {
        label: "Home Address",
        value:
          notification?.encounter?.json?.encounter?.patient?.address?.[0]?.text,
      },
      { label: "PCP", value: notification?.encounter?.pcp_name },
      { label: "NPI", value: notification?.encounter?.mpi_id },
      {
        label: "Gender Identity",
        value: notification?.encounter?.gender === "M" ? "Male" : "Female",
      }, // Map gender
      {
        label: "Sex Assigned at Birth",
        value: "*Male",
      },
      { label: "Sexual Orientation", value: "*Heterosexual (finding)" },
      {
        label: "Deceased",
        value: notification?.encounter?.json?.encounter?.patient
          ?.deceased_boolean
          ? "Yes"
          : "No",
      },
    ],

    organisationPanels:
      notification?.notificationsPanels?.map((notificationPanel: any) => ({
        panelName:
          notificationPanel.panel.display_name ||
          notificationPanel.panel.panel_name,
        mrn: notificationPanel?.patient_identifier,
      })) || [],

    encounter: [
      { label: "Facility", value: "*Clovis Community Medical Center (CMC)" },
      {
        label: "Patient Class",
        value:
          patientClassMap.get(
            notification?.encounter?.json?.encounter?.class
          ) ||
          notification?.encounter?.json?.encounter?.class ||
          "-",
      },
      {
        label: "Event Type",
        value:
          notification?.encounter?.json?.encounter?.event_type_description ||
          "-",
      },
      {
        label: "Event Date/Time",
        value:
          formatDate(
            notification?.encounter?.json?.encounter?.planned_end_date
          ) || "-",
      },
      {
        label: "Admit Source",
        value:
          notification?.encounter?.json?.encounter?.admission_source || "-",
      },
      {
        label: "Admit Date/Time",
        value:
          formatDate(
            notification?.encounter?.json?.encounter?.planned_start_date
          ) || "-",
      },
      {
        label: "Admitting Clinician",
        value: `${notification?.encounter?.json?.encounter?.admitting_clinician_given_name} ${notification?.encounter?.json?.encounter?.admitting_clinician_family_name}`,
      },
      {
        label: "Admission Type",
        value: notification?.encounter?.json?.encounter?.admission_type || "-",
      },
      {
        label: "Hospital Service",
        value: notification?.encounter?.json?.encounter?.service_type || "-",
      },
      {
        label: "Discharge Date/Time",
        value:
          formatDate(
            notification?.encounter?.json?.encounter?.planned_end_date
          ) || "-",
      },
      {
        label: "Discharge Disposition",
        value:
          notification?.encounter?.discharge_disposition ||
          "Discharge with approval",
      },
      {
        label: "No. of ED Visits",
        value: "*5 in the last 6 months",
      },
      {
        label: "No. of IP Visits",
        value: "*2 in the last 6 months",
      },
    ],

    products:
      (notification?.encounter?.json?.encounter?.conditions.length &&
        notification?.encounter?.json?.encounter?.conditions?.map(
          (condition: any) => ({
            code: formatDateTime(condition.recorded_date) || "-",
            name: condition.type,
            category: condition.code,
            quantity: condition.display,
            makras: "Marked as",
            action: "*Completed",
            performedby: "*June Ryan",
          })
        )) ||
      [],

    laboratoryResults:
      (notification?.encounter?.json?.encounter?.service_request?.observations
        ?.length && [
        {
          test:
            notification?.encounter?.json?.encounter?.service_request?.test ||
            "Unknown Test",
          observationDate:
            formatDateTime(
              notification?.encounter?.json?.encounter?.service_request
                ?.result_date
            ) || "-",
          lastResultDate:
            formatDateTime(
              notification?.encounter?.json?.encounter?.service_request.last_status_date
            ) || "-",
          source:
            notification?.encounter?.json?.encounter?.service_request?.source ||
            "-",  
          status:
            notification?.encounter?.json?.encounter?.service_request?.status ||
            "-",
          testData:
            notification?.encounter?.json?.encounter?.service_request?.observations?.map(
              (obs: any) => ({
                item: obs?.display || "Unknown Item",
                date: formatDateTime(obs?.observation_date) || "-",
                resultValue: obs?.value || "No Result",
                unit: obs?.unit || "-",
                referenceRange: obs?.reference_range || "N/A",
                messageFlag: obs?.interpretation || "",
              })
            ),
        },
      ]) ||
      [],

    comments:
      notification?.comments?.map((comment: IComment) => ({
        ...comment,
        isEditable: comment?.user?.email === currentUser.email,
        isDeletable: comment?.user?.email === currentUser.email,
      })) || [],
  };
};

export const adtCodes = [
  { label: "Admission", value: "A01" },
  { label: "Transfer", value: "A02" },
  { label: "Emergency Discharge", value: "A03E" },
  { label: "Planned Discharge", value: "A03P" },
  { label: "Discharge", value: "A03" },
  { label: "Leave of Absence", value: "A06" },
  { label: "Change of Attending Physician", value: "A07" },
  { label: "Referral", value: "A08" },
  { label: "Change of Ward", value: "A09" },
  { label: "Return from Leave of Absence", value: "A05" },
  { label: "Readmission", value: "A01R" },
  { label: "Transfer to Another Facility", value: "A02F" },
  { label: "Transfer Within Facility", value: "A02W" },
  { label: "Death", value: "A03D" },
  { label: "Discharge to Home", value: "A03H" },
  { label: "Transfer to Hospice", value: "A02H" },
  { label: "Transfer to Rehabilitation", value: "A02R" },
  { label: "Discharge to Another Facility", value: "A03F" },
  { label: "Admission for Observation", value: "A01O" },
  { label: "Patient Expired", value: "A03X" },
  { label: "Transfer to ICU", value: "A02I" },
  { label: "Transfer to SNF (Skilled Nursing Facility)", value: "A02S" },
  { label: "Admission from Outpatient", value: "A01O" },
  { label: "Leave of Absence (Medical)", value: "A06M" },
  { label: "Leave of Absence (Personal)", value: "A06P" },
  { label: "Admission for Surgery", value: "A01S" },
  { label: "Discharge Against Medical Advice", value: "A03AMA" },
  { label: "Change of Room", value: "A09R" },
  { label: "Change of Hospital Service", value: "A09S" },
  { label: "Admission from ED", value: "A01ED" },
  { label: "Transfer to Outpatient", value: "A02O" },
  { label: "Discharge to Family Care", value: "A03FC" },
  { label: "Discharge to Skilled Nursing Facility", value: "A03SN" },
  { label: "Discharge to Home Health Care", value: "A03HH" },
  { label: "Discharge to Palliative Care", value: "A03PC" },
  { label: "Admission to Research", value: "A01R" },
  { label: "Temporary Leave", value: "A06T" },
  { label: "Observation Discharge", value: "A03O" },
  { label: "Transfer to Dialysis", value: "A02D" },
  { label: "Elective Admission", value: "A01E" },
  { label: "Involuntary Admission", value: "A01I" },
  { label: "Admission from Home", value: "A01H" },
  { label: "Admission for Medical Assessment", value: "A01MA" },
  { label: "Outpatient Procedure Admission", value: "A01OP" },
  { label: "Transfer to Psychiatric Unit", value: "A02P" },
  { label: "Discharge to Long-Term Care", value: "A03LT" },
  { label: "Transfer to Adult Day Care", value: "A02AD" },
  { label: "Discharge to Community Services", value: "A03CS" },
  { label: "Transfer to Home Care", value: "A02HC" },
  { label: "Discharge to Rehabilitation Facility", value: "A03RF" },
  { label: "Transfer to Specialty Care", value: "A02SC" },
  { label: "Admission for Diagnostic Testing", value: "A01DT" },
  { label: "Transfer to Observation Unit", value: "A02OU" },
  { label: "Discharge to Adult Foster Care", value: "A03AF" },
  { label: "Discharge for Further Treatment", value: "A03FT" },
  { label: "Discharge for Home Modifications", value: "A03HM" },
  { label: "Discharge to Assisted Living", value: "A03AL" },
  { label: "Discharge to Family Member", value: "A03FM" },
  { label: "Admission for Substance Abuse Treatment", value: "A01SA" },
  { label: "Transfer to Psychiatric Hospital", value: "A02PH" },
  { label: "Admission for Pain Management", value: "A01PM" },
  { label: "Discharge to Home with Home Health", value: "A03HH" },
  { label: "Discharge for Community Resources", value: "A03CR" },
  { label: "Transfer to Pediatric Unit", value: "A02PU" },
  { label: "Transfer to Geriatric Unit", value: "A02GU" },
  { label: "Emergency Admission", value: "A01EA" },
  { label: "Voluntary Admission", value: "A01VA" },
];

export function getADTCodeLabel(code: string) {
  return adtCodes.find((adtCode) => adtCode.value === code)?.label || code;
}
