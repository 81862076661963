import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { SavedFilter, Filter, FilterService } from "../services/filter.service";
import { FilterFormComponent } from "../filter-form/filter-form.component";
import { StateService } from "../services/state.service";
import { take } from "rxjs";
import { SavedFilterListComponent } from "../ui/molecule/saved-filter-list/saved-filter-list.component";

@Component({
  selector: "app-filters",
  templateUrl: "./filters.component.html",
  styleUrl: "./filters.component.scss",
})
export class FiltersComponent {
  @Input() visible: boolean = false;
  @Output() close = new EventEmitter<void>();
  @Output() applied = new EventEmitter<number>();
  @Output() applyFilters = new EventEmitter<Filter[]>();

  @ViewChild(FilterFormComponent) filterFormComponent!: FilterFormComponent;
  @ViewChild(SavedFilterListComponent)
  savedFilterListComponent!: SavedFilterListComponent;

  appliedFilter: SavedFilter | null = null;
  savedFilters: SavedFilter[] = [];
  editedFilter: SavedFilter | null = null;
  filterToBeSaved: SavedFilter | null = null;
  activeTabIndex: number = 0;
  disableApplyORSave: boolean = true;

  loadingSavedFilters: boolean = true;
  // edit: boolean = false;

  constructor(
    public filterService: FilterService,
    public stateService: StateService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.filterService
        .getAllSavedFilters()
        .pipe(take(1))
        .subscribe({
          next: (data) => {
            this.savedFilters = data;
            this.loadingSavedFilters = false;
          },
          error: (err) => {
            // console.error("Error while subscribing:", err);
          },
        });
    }, 5000);
  }

  clearAppliedFilters() {
    // this.filterFormComponent.formReset();
    this.appliedFilter = null;
    // this.editedFilter = null;
    this.applied.emit(0);
  }

  handleDisableApplyORSave(bool: boolean) {
    this.disableApplyORSave = bool;
  }

  handleApply() {
    this.filterFormComponent.clickApplyFilter();
  }

  handleApplyFilter(filters: Filter[]) {
    this.appliedFilter = { filterValues: filters };
    this.applied.emit(filters.length);
  }

  handleApplyFilterFromSave(savedFilter: SavedFilter) {
    this.appliedFilter = savedFilter;
    this.activeTabIndex = 0;
    this.applied.emit(this.appliedFilter.filterValues.length);
    this.closeSidebar();
  }

  handleEditFilter(editedFilter: SavedFilter) {
    this.editedFilter = editedFilter;
    this.activeTabIndex = 0;
  }

  handleSave() {
    this.filterFormComponent.clickSaveFilter();
  }

  handleAppliedFilterRemoveSingle(chip: { key: string; value: string }) {
    if (this.editedFilter)
      this.editedFilter.filterValues = this.editedFilter.filterValues.filter(
        (af) => af.key !== chip.key
      );
  }

  handleSaveFilterFromSave(filter: SavedFilter) {
    this.editedFilter = null;
    this.activeTabIndex = 1;
    this.filterService
      .saveFilter(filter)
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          this.filterService.getAllSavedFilters().subscribe({
            next: (data) => {
              this.savedFilters = data;
            },
            error: (err) => {
              // console.error("Error while subscribing:", err);
            },
          });
        }
      });
  }

  handleDeleteFilter(filter: SavedFilter) {
    if (filter.id)
      this.filterService
        .delete(filter)
        .pipe(take(1))
        .subscribe((data) => {
          if (data) {
            this.filterService.getAllSavedFilters().subscribe({
              next: (data) => {
                this.savedFilters = data;
              },
              error: (err) => {
                // console.error("Error while subscribing:", err);
              },
            });
          }
        });
    else this.savedFilters = this.savedFilters.filter((f) => f != filter);
  }

  handleSaveFilterFromForm(filter: SavedFilter) {
    if (filter.filterValues.length > 0) {
      this.activeTabIndex = 1;
    }
    if (this.editedFilter && filter.id && this.savedFilters) {
      this.savedFilters = this.savedFilters?.map((f) => {
        if (f.id == filter.id) {
          return { ...filter, isEditing: true };
        }
        return { ...f };
      });
    } else {
      let editedFilter = this.savedFilters.filter((sf) => sf.isEditing == true);
      if (!editedFilter.length) {
        this.savedFilters = [
          ...(this.savedFilters ?? []),
          { filterValues: filter.filterValues, isEditing: true },
        ];
      } else {
        this.savedFilters[this.savedFilters.length - 1].filterValues =
          filter.filterValues;
      }
    }
  }

  handleCancel() {
    this.filterFormComponent.clickCancel();
    this.closeSidebar();
    this.filterService
      .getAllSavedFilters()
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          this.savedFilters = data;
          this.loadingSavedFilters = false;
        },
        error: (err) => {
          // console.error("Error while subscribing:", err);
        },
      });
  }

  closeSidebar(): void {
    this.savedFilterListComponent.close();
    this.activeTabIndex = 0;
    // this.editedFilter = null
    this.close.emit();
  }

  handleResetEvent() {
    this.savedFilterListComponent.close();
    this.savedFilters = this.savedFilters.filter((sf) => sf.id);
  }

  handleApplyFilters(filters: Filter[]) {
    this.applyFilters.emit(filters);
  }
}
