import { Component, Input } from "@angular/core";
import { toCamelCase } from "../../../util/common.util";

@Component({
  selector: "mxn-table",
  templateUrl: "./table.component.html",
  styleUrl: "./table.component.scss",
})
export class TableComponent {
  @Input() columns: { [key: string]: string } = {};
  @Input() nestedColumns?: { [key: string]: string } = {};
  @Input() data: any[] = [];
  toCamelCase = toCamelCase;
  // Default classes for header and body cells
  @Input() headerClasses: string[] = [
    "w-15rem py-2 pr-2 pl-4",
    "w-15rem py-2 px-2",
    "px-2 py-2",
  ];
  @Input() bodyClasses: string[] = [
    "p-2 pl-4 mxn-td-label",
    "p-2 mxn-td-label",
    "p-2 mxn-td-info",
  ];
  @Input() nestedHeaderClasses: string[] = [
    "w-15rem py-2 pr-2 pl-4",
    "w-15rem py-2 px-2",
    "px-2 py-2",
  ];
  @Input() nestedBodyClasses: string[] = [
    "p-2 pl-4 mxn-td-label",
    "p-2 mxn-td-label",
    "p-2 mxn-td-info",
  ];

  getColumnKeys() {
    return Object.keys(this.columns);
  }
  getNestedColumnKeys() {
    return this.nestedColumns ? Object.keys(this.nestedColumns) : null;
  }
  getShowNestedTable(rowData: any) {
    return rowData?.testData;
  }
  getActionCellData(data: string) {
    if (!data) return "";
    return `${
      data.toLocaleLowerCase().includes("commented")
        ? `<strong>${data}</strong>`
        : `Marked as <strong>${data}</strong>`
    }`;
  }
}
