import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import {
  INotification,
  NOTIFICATION_STATUS,
} from "../../../models/notification.model";
import { NotificationService } from "../../../services/notification/notification.service";
import { getADTCodeLabel } from "../../../mock/notification.mock";

@Component({
  selector: "mxn-notification-list",
  templateUrl: "./notification-list.component.html",
  styleUrl: "./notification-list.component.scss",
})
export class NotificationListComponent {
  constructor(private notificationService: NotificationService) {}
  @Input() notifications: INotification[] = [];
  @Input() loading: boolean = true;
  @Input() totalNotifications: number = 0;
  selectedNotification: any;

  @Output() onNotificationSelect: EventEmitter<INotification> =
    new EventEmitter<INotification>();
  @Output() getNotificationCall: EventEmitter<INotification> =
    new EventEmitter<INotification>();
  @Output() scrollToComments: EventEmitter<void> = new EventEmitter<void>();

  onNotificationClick(id: any) {
    const foundNotification = this.notifications?.find(
      (notification) => notification.id === id
    );
    if (foundNotification) {
      this.selectedNotification = foundNotification;
      this.onNotificationSelect.emit(foundNotification);
    }
  }
  updateNotificationStatusCall(notificationId: string, status: string) {
    this.notificationService
      .updateNotificationStatus(notificationId, status)
      .subscribe({
        next: (res) => {
          this.getNotificationCall.emit();
        },
        error: (err) => {
          console.error("Error while subscribing:", err);
        },
      });
  }
  getPanelNames(notification: INotification) {
    return notification.notificationsPanels?.map((item) => {
      return item.panel.display_name;
    });
  }
  getCardStyleClasses = (status: string) => {
    switch (status) {
      case NOTIFICATION_STATUS.COMPLETED:
        return "acc-tab-completed";
      case NOTIFICATION_STATUS.IN_PROGRESS:
        return "acc-tab-inprogress";
      case NOTIFICATION_STATUS.NOT_STARTED:
      default:
        return "acc-tab-notstarted";
    }
  };
  scrollToCommentsHandler() {
    this.scrollToComments.emit();
  }
  getADTLabel(code: string) {
    const label = getADTCodeLabel(code);
    if (label === code) {
      return label;
    }
    return label + " (" + code + ")";
  }
}
