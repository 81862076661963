import { Injectable } from "@angular/core";
import { catchError, Observable, of } from "rxjs";
import { ServiceClientService } from "./service-client.service";
import { environment } from "../../environments/environment";
import { HttpErrorResponse } from "@angular/common/http";
import { endpoints } from "../../environments/endpoints";

export interface Filter {
  key: string;
  value: string;
  operation: string;
  metaData?: any;
  multi: boolean;
  state?: string
}
export interface SavedFilter {
  id?: number;
  name?: string;
  isEditing?: boolean | null;
  filterValues: Filter[];
}

@Injectable({
  providedIn: "root",
})
export class FilterService {
  constructor(public serviceClientService: ServiceClientService) {}

  getAllSavedFilters(): Observable<SavedFilter[]> {
    // return of([])
    return this.serviceClientService.get(endpoints.getSavedFilters)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        console.error("Error fetching saved filters:", error);
        return of([]);
      })
    );
  }

  saveFilter(filter: SavedFilter): Observable<SavedFilter> {
    if (filter.id) {
      return this.serviceClientService.put(
        endpoints.updateFilter + "/" + filter.id,
        {
          name: filter.name,
          filters: filter.filterValues,
        }
      );
    } else {
      return this.serviceClientService.post(endpoints.createFilter, {
        name: filter.name,
        filters: filter.filterValues,
      });
    }
  }

  delete(filter: SavedFilter): Observable<any> {
    if (filter && filter.id) {
      return this.serviceClientService.delete(
        endpoints.deleteFilter + "/" + filter.id
      );
    } else {
      return of(null);
    }
  }
}
