import { Component, EventEmitter, Input, Output } from "@angular/core";
import { filterKeys } from "../../../constants/filters.constants";

@Component({
  selector: "app-chips-list",
  templateUrl: "./chips-list.component.html",
  styleUrl: "./chips-list.component.scss",
})
export class ChipsListComponent {
  @Input() chips: { key: string; value: string, operation?: string }[] = []; // List of chips to display
  @Input() editable: boolean = true; // Whether the chips are editable
  @Input() removable: boolean = false; // Whether chips can be removed
  @Input() addOnBlur: boolean = true; // Adds new chip on blur
  @Input() addOnTab: boolean = true; // Adds new chip on tab press
  @Input() showKey: boolean = true;
  @Input() showValue: boolean = true;

  @Output() chipsChange = new EventEmitter<any[]>(); // Emits the updated chips array
  @Output() removedChip = new EventEmitter<any>(); // Emits the updated chips array

  filterKeys = filterKeys;
  // Event to handle chip addition or removal
  onChipsChange(event: any) {
    this.chipsChange.emit(this.chips);
  }

  // Remove chip when "X" is clicked
  removeChip(chip: any) {
    this.chips = this.chips.filter((c) => c !== chip);
    this.chipsChange.emit(this.chips);
    this.removedChip.emit(chip);
  }

  showIcon(key: string): boolean {
    switch (key) {
      case 'gender':
        return true;
      default:
        return false;
    }
  }

}
