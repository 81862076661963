import { MX } from "./environment";

export const endpoints = {
  getNotifications: MX.notificationServiceBaseURL + "/notification",
  getNotificationById: MX.notificationServiceBaseURL + "/notification",
  createComment: MX.notificationServiceBaseURL + "/comments",
  getComments: MX.notificationServiceBaseURL + "/comments",
  getCommentByNotificationId:
    MX.notificationServiceBaseURL + "/comments/{notification_id}",
  updateComment: MX.notificationServiceBaseURL + "/comments",
  deleteComment: MX.notificationServiceBaseURL + "/comments",
  getCSRFToken: MX.notificationServiceBaseURL + "/csrf-token",
  getSavedFilters: MX.notificationServiceBaseURL + "/filters",
  createFilter: MX.notificationServiceBaseURL + "/filters",
  updateFilter: MX.notificationServiceBaseURL + "/filters",
  deleteFilter: MX.notificationServiceBaseURL + "/filters",
  userSettings: MX.notificationServiceBaseURL + "/user-settings",
};
