import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Filter, FilterService, SavedFilter } from "../services/filter.service";
import { filterKeys, operatorList } from "../constants/filters.constants";
import { Options } from "@angular-slider/ngx-slider";
import { DatePipe } from "@angular/common";
import { InputNumberInputEvent } from "primeng/inputnumber";

interface Facility {
  name: string;
  value: string;
}

interface AdvancedFilter {
  key: string;
  operator: string;
  value: string;
}

interface GenderCheckBox {
  value: string;
  checked: boolean;
}

interface PatientClassCheckBox {
  value: string;
  checked: boolean;
}

interface EventCheckBox {
  value: string;
  checked: boolean;
}

enum HEADING {
  SELECTED = "selected",
  APPLIED = "applied",
  EDITED = "edited",
}

@Component({
  selector: "app-filter-form",
  templateUrl: "./filter-form.component.html",
  styleUrl: "./filter-form.component.scss",
})
export class FilterFormComponent {
  @Input() visible: boolean = false;
  @Input() editedFilter: SavedFilter | null = null;
  @Input() appliedFilter: SavedFilter | null = null;
  @Output() save = new EventEmitter<SavedFilter>();
  @Output() apply = new EventEmitter<Filter[]>();
  @Output() closeSidebarEvent = new EventEmitter<void>();
  @Output() resetEvent = new EventEmitter<void>();
  @Output() applyFilters = new EventEmitter<Filter[]>();
  @Output() disableApplyORSaveEvent = new EventEmitter<boolean>();

  _filter: SavedFilter | null = null;
  heading: string = HEADING.SELECTED;
  filterActiveIndexes: number[] | null = [0, 6];
  ingredient: any;
  selectedtimeinterval: any;
  value: any;
  first: boolean = true;
  timeinterval: any[] | undefined;
  selectedDates: Date[] = [];
  showInlineCalander: boolean = false;
  showMultiEventDate: boolean = false;
  eventDateRangePlaceHolder: string = "Start date - End date";
  selectedRange: string = "custom";

  constructor(
    public filterService: FilterService,
    private datePipe: DatePipe
  ) {}

  genderFilterList = [
    { label: "Male", value: "male", icon: "pi pi-check", checked: false },
    { label: "Female", value: "female", icon: "pi pi-times", checked: false },
    { label: "Other", value: "other", icon: "pi pi-star", checked: false },
  ];

  filterForm: FormGroup = new FormGroup({
    eventDate: new FormControl<string>(""),
    eventDateRange: new FormControl<any>(null),
    multiEventDate: new FormControl<boolean | null>(null),
    eventDateOperation: new FormControl<string | null>(null),
    gender: new FormControl<{ value: any; checked: boolean }[]>([]),
    isAgeRange: new FormControl<boolean | null>(null),
    ageRange: new FormControl(null),
    age: new FormControl(null),
    ageOperation: new FormControl(null),
    patientClass: new FormControl(null),
    event: new FormControl(null),
    facility: new FormControl<Facility[]>([]),
  });

  highValue: number = 60;
  options: Options = {
    floor: 0,
    ceil: 100,
    disabled: true,
    draggableRange: false,
  };

  ngOnInit() {
    this.disableinitialFields();
    this.age?.setValidators([Validators.min(1), Validators.max(200)]);

    this.filterForm.valueChanges.subscribe((data) => {
      let filter = this.mapFormToFilter();
      this._filter = { ...this._filter, filterValues: filter };
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.visible && this.heading !== HEADING.APPLIED) {
      this.formReset();
      this.heading = HEADING.SELECTED
    }
    if (changes["editedFilter"]) {
      const previousValue = changes["editedFilter"].previousValue;
      const currentValue = changes["editedFilter"].currentValue;
      const firstChange = changes["editedFilter"].firstChange;
      if (currentValue && currentValue !== previousValue && !firstChange) {
        this.formReset();
        this.initializeFilterForm(currentValue);
        this._filter = currentValue;
        this.heading = HEADING.EDITED;
      }
      if (currentValue == null) {
        this.formReset();
        this._filter = null;
        this.heading = HEADING.SELECTED;
      }
    }
    if (changes["appliedFilter"]) {
      const previousValue = changes["appliedFilter"].previousValue;
      const currentValue = changes["appliedFilter"].currentValue;
      const firstChange = changes["appliedFilter"].firstChange;
      if (currentValue && currentValue !== previousValue && !firstChange) {
        this.formReset();
        this.initializeFilterForm(currentValue);
        this._filter = currentValue;
        this.heading = HEADING.APPLIED;
        this.applyFilters.emit(this._filter?.filterValues);
      }
      if (currentValue == null) {
        this.formReset();
        this._filter = null;
        this.heading = HEADING.SELECTED;
      }
    }
  }

  eventDateTimeIntervalList = [
    { name: "Last 1 hour", value: "Last 1 hour" },
    { name: "Last 4 hour", value: "Last 4 hour" },
    { name: "Last 6 hour", value: "Last 6 hour" },
    { name: "Last 24 hour", value: "Last 24 hour" },
  ];

  patientClassList = [
    { label: "Emergency", value: "emergency", checked: false },
    { label: "Outpatient", value: "outpatient", checked: false },
    { label: "Inpatient", value: "inpatient", checked: false },
    { label: "COVID", value: "covid", checked: false },
  ];

  eventList = [
    { label: "Admit", value: "Admit", checked: false },
    {
      label: "Transfer Inpatient to Outpatient",
      value: "Transfer Inpatient to Outpatient",
      checked: false,
    },
    { label: "Discharge", value: "Discharge", checked: false },
    { label: "Cancel Admit", value: "Cancel Admit", checked: false },
    { label: "Registration", value: "Registration", checked: false },
    { label: "Cancel Transfer", value: "Cancel Transfer", checked: false },
    {
      label: "Transfer Outpatient to Inpatient",
      value: "Transfer Outpatient to Inpatient",
      checked: false,
    },
    { label: "Cancel Discharge", value: "Cancel Discharge", checked: false },
  ];

  operatorList = operatorList;

  signOperator = [
    { name: "=", operation: "=" },
    { name: "≠", operation: "≠" },
  ];

  facilityList = [
    {
      name: "Iron deficiency anemia secondary to blood loss (chronic)",
      value: "Iron deficiency anemia secondary to blood loss (chronic)",
    },
    {
      name: "Personal History of Urinary Calculi",
      value: "Personal History of Urinary Calculi",
    },
    {
      name: "Calculus of kidney with calculus of ureter",
      value: "Calculus of kidney with calculus of ureter",
    },
    {
      name: "Elevated white blood cell count, unspecified",
      value: "Elevated white blood cell count, unspecified",
    },
    {
      name: "Essential (primary) hypertension",
      value: "Essential (primary) hypertension",
    },
    {
      name: "Benign prostatic hyperplasia without lower urinary tract symptoms",
      value:
        "Benign prostatic hyperplasia without lower urinary tract symptoms",
    },
    {
      name: "Calculus of kidney",
      value: "Calculus of kidney",
    },
    {
      name: "Urinary tract Infection, site not specified",
      value: "Urinary tract Infection, site not specified",
    },
    {
      name: "Acute kidney failure with tubular necrosis",
      value: "Acute kidney failure with tubular necrosis",
    },
    {
      name: "Other osteonecrosis, right femur",
      value: "Other osteonecrosis, right femur",
    },
    {
      name: "Contact with and (suspected) exposure to COVID-19",
      value: "Contact with and (suspected) exposure to COVID-19",
    },
    {
      name: "Presence of unspecified artificial hip joint",
      value: "Presence of unspecified artificial hip joint",
    },
    {
      name: "Presence of right artificial knee joint",
      value: "Presence of right artificial knee joint",
    },
    {
      name: "Allergy status to other drugs, medicaments and biological substances",
      value:
        "Allergy status to other drugs, medicaments and biological substances",
    },
    {
      name: "Family history of ischemic heart disease and other diseases of the circulatory system",
      value:
        "Family history of ischemic heart disease and other diseases of the circulatory system",
    },
  ];

  advanceItems = [
    { name: "Admit Reason", value: "admit_reason" },
    { name: "DX Codes", value: "dx_codes" },
    { name: "PCB name", value: "pcb_name" },
    { name: "Discharge Disposition", value: "discharge_disposition" },
  ];

  advanceFiltersValueList = [
    { name: "ABD Pain Abdominal Pain", value: "ABD Pain Abdominal Pain" },
  ];

  get eventDate() {
    return this.filterForm.get("eventDate");
  }
  get eventDateRange() {
    return this.filterForm.get("eventDateRange");
  }
  get multiEventDate() {
    return this.filterForm.get("multiEventDate");
  }
  get eventDateOperation() {
    return this.filterForm.get("eventDateOperation");
  }
  get gender() {
    return this.filterForm.get("gender");
  }
  get isAgeRange() {
    return this.filterForm.get("isAgeRange");
  }
  get ageRange() {
    return this.filterForm.get("ageRange");
  }
  get age() {
    return this.filterForm.get("age");
  }
  get ageOperation() {
    return this.filterForm.get("ageOperation");
  }
  get patientClass() {
    return this.filterForm.get("patientClass");
  }
  get event() {
    return this.filterForm.get("event");
  }
  get facility() {
    return this.filterForm.get("facility");
  }

  openTabs(indexes: number[]) {
    this.filterActiveIndexes = indexes;
  }

  closeTabs() {
    this.filterActiveIndexes = [];
  }

  disableinitialFields() {
    this.eventDate?.disable();
    this.eventDateOperation?.disable();
    this.eventDateRange?.disable();
    this.age?.disable();
    this.ageRange?.disable();
    this.ageOperation?.disable();
  }

  toggleMultiEventDate() {
    this.filterForm?.patchValue({
      multiEventDate: !this.multiEventDate?.value,
    });
  }

  handleEventDateOperationChange(event: { name: string; value: string }) {
    this.filterForm.patchValue({
      eventDateOperation: event.value,
    });
  }

  handleEventDateToggle(value: boolean) {
    if (value == false) {
      this.eventDate?.enable();
      this.eventDateOperation?.enable();
      this.eventDateRange?.disable();
      this.showInlineCalander = false;
    } else {
      this.eventDate?.disable();
      this.eventDateOperation?.disable();
      this.eventDateRange?.enable();
      this.showInlineCalander = false;
    }
  }

  handleCalanderFocus() {
    this.showInlineCalander = !this.showInlineCalander;
  }
  toggleCalander(event: any) {
    this.showInlineCalander = !this.showInlineCalander;
  }
  closeInlineCalander() {
    if (this.eventDateRange?.value[0] && this.eventDateRange?.value[1])
      this.showInlineCalander = false;
  }
  handleClearEventDateRange(event: Event) {
    this.selectedRange = "custom";
    this.showInlineCalander = false;
  }

  setLastWeek() {
    // const today = new Date();
    // const lastWeekStart = new Date(today);
    // lastWeekStart.setDate(today.getDate() - 7); // Start date is 7 days ago
    // this.selectedDates = [lastWeekStart, today];
    this.selectedRange = "week";
    const today = new Date();
    const lastWeekStart = new Date(today);
    lastWeekStart.setDate(today.getDate() - 7);
    const lastWeekEnd = new Date(today);
    this.filterForm.patchValue({
      eventDateRange: [lastWeekStart, lastWeekEnd],
    });
    this.closeInlineCalander();
  }

  setLastMonth() {
    //   const today = new Date();
    // const lastMonthStart = new Date(today);
    // lastMonthStart.setDate(today.getDate() - 30); // Start date is 30 days ago
    // this.selectedDates = [lastMonthStart, today];
    this.selectedRange = "month";
    const today = new Date();
    const lastMonthStart = new Date(today);
    lastMonthStart.setMonth(today.getMonth() - 1);
    lastMonthStart.setDate(1);

    const lastMonthEnd = new Date(lastMonthStart);
    lastMonthEnd.setMonth(lastMonthStart.getMonth() + 1);
    lastMonthEnd.setDate(0);

    this.filterForm.patchValue({
      eventDateRange: [lastMonthStart, lastMonthEnd],
    });
    this.closeInlineCalander();
  }

  setCustomRange() {
    this.selectedRange = "custom";
    this.filterForm.patchValue({
      eventDateRange: [],
    });
  }

  handleAgeInput(event: InputNumberInputEvent) {
    if (event.value == 0) {
      this.filterForm.patchValue({
        age: null,
      });
    }
  }

  handleGenderFilterChange(selectedCheckboxes: GenderCheckBox[]) {
    this.filterForm.patchValue({
      gender: selectedCheckboxes,
    });
  }

  handleAgeToggle() {
    if (this.isAgeRange?.value && this.isAgeRange?.value == true) {
      this.ageRange?.enable();
      this.age?.disable();
      this.ageOperation?.disable();
      this.options = { ...this.options, disabled: false, draggableRange: true };
    } else {
      this.ageRange?.disable();
      this.age?.enable();
      this.ageOperation?.enable();
      this.options = { ...this.options, disabled: true, draggableRange: false };
    }
  }

  validateRange(event: any) {
    if (event?.values) {
      if (event.values[0] > event.values[1]) {
        this.filterForm.patchValue({
          ageRange: [event.values[1], event.values[1]],
        });
      }
    }
  }

  isAgeDisabled() {
    const ageControl = this.age;
    return ageControl ? ageControl.disabled : false;
  }

  handleAgeOperationChange($event: any) {
    this.filterForm.patchValue({ ageOperation: $event });
  }

  handlePatientClassChange(
    selectedCheckboxes: { value: any; checked: boolean }[]
  ) {
    this.filterForm.patchValue({
      patientClass: selectedCheckboxes,
    });
  }

  handleEventChange(selectedCheckboxes: { value: any; checked: boolean }[]) {
    this.filterForm.patchValue({
      event: selectedCheckboxes,
    });
  }

  handleEventDataRangeChange() {
    this.selectedRange = "custom";
    if (this.eventDateRange?.value[0] && this.eventDateRange?.value[1]) {
      this.filterForm.patchValue({
        eventDateRange: this.eventDateRange.value,
      });
      this.showInlineCalander = false;
    }
  }

  handleEventDataRangeClear() {
    this.selectedRange = "custom";
    this.showInlineCalander = false;
  }

  handleFacilityChange(selected: { key: string; value: string }) {
    if (selected && !this.facility?.value?.includes(selected)) {
      this.filterForm.patchValue({
        facility: [...this.facility?.value, selected],
      });
    }
  }

  handleFacilityChipListChange(chips: { key: string; value: string }[]) {
    this.filterForm.patchValue({ facility: chips });
  }

  advanceFiltersItems: AdvancedFilter[] = [
    {
      key: "",
      operator: "",
      value: "",
    },
  ];

  addComboBox() {
    if (this.advanceFiltersItems.length < 5) {
      this.advanceFiltersItems.push({
        key: "",
        operator: "",
        value: "",
      });
    }
  }

  handleAdvanceItemChange(
    advanceItem: { name: string; value: string },
    index: number
  ) {
    this.advanceFiltersItems[index].key = advanceItem.value;
    if (
      this.advanceFiltersItems[index].value !== "" &&
      this.advanceFiltersItems[index].operator != ""
    ) {
      if (this._filter) {
        this._filter.filterValues = this.mapFormToFilter();
      } else {
        this._filter = { filterValues: this.mapFormToFilter() };
      }
    }
  }

  handleAdvanceOperatorListChange(
    operator: { name: string; value: string },
    index: number
  ) {
    this.advanceFiltersItems[index].operator = operator.name;
    if (
      this.advanceFiltersItems[index].key !== "" &&
      this.advanceFiltersItems[index].value != ""
    ) {
      if (this._filter) {
        this._filter.filterValues = this.mapFormToFilter();
      } else {
        this._filter = { filterValues: this.mapFormToFilter() };
      }
    }
  }

  handleAdvanceValueChange(
    selected: { key: string; value: string },
    index: number
  ) {
    if (selected) {
      this.advanceFiltersItems[index].value = selected.value;
    } else {
      this.advanceFiltersItems[index].value = "";
    }
    if (
      this.advanceFiltersItems[index].key !== "" &&
      this.advanceFiltersItems[index].operator != ""
    ) {
      if (this._filter) {
        this._filter.filterValues = this.mapFormToFilter();
      } else {
        this._filter = { filterValues: this.mapFormToFilter() };
      }
    }
  }

  handleRemoveAdvancedFilters(index: number) {
    if (this.advanceFiltersItems.length === 1) {
      this.advanceFiltersItems = [
        {
          key: "",
          operator: "",
          value: "",
        },
      ];
    } else {
      this.advanceFiltersItems = this.advanceFiltersItems.filter(
        (_ad, i) => index !== i
      );
    }
    if (this._filter) {
      this._filter.filterValues = this.mapFormToFilter();
    }
  }

  isAdvanceFilterAddButtonDisabled(index: number) {
    if (
      this.advanceFiltersItems[index].key === "" ||
      this.advanceFiltersItems[index].operator === "" ||
      this.advanceFiltersItems[index].value === ""
    )
      return true;
    else return false;
  }

  handleActiveIndexChange(indexes: any) {
    if (indexes?.length) {
      this.filterActiveIndexes = indexes;
    }
  }

  handleSave() {
    let filterValues = this.mapFormToFilter();
    if (filterValues.length > 0) {
      if (this._filter?.id) {
        this.save.emit({
          ...this._filter,
          filterValues: filterValues,
        });
      } else this.save.emit({ isEditing: true, filterValues: filterValues });
    }
  }

  handleApply() {
    this.first = false;
    this.heading = HEADING.APPLIED;
    this.applyFilters.emit(this._filter?.filterValues);
    this.closeSidebar();
  }

  public clickApplyFilter() {
    if (this._filter?.filterValues.length) this.handleApply();
  }

  public clickSaveFilter() {
    this.handleSave();
  }

  public clickCancel() {
    this.handleCancel();
  }

  handleCancel() {
    this.closeSidebar();
  }

  formReset() {
    this.filterForm.reset({
      eventDate: "",
      eventDateRange: null,
      multiEventDate: null,
      eventDateOperation: null,
      gender: [],
      isAgeRange: null,
      ageRange: null,
      age: null,
      ageOperation: null,
      patientClass: [],
      event: [],
      facility: [],
    });
    this.ageRange?.setValue([0, 19]);
    this.disableinitialFields();
    this.handleAgeToggle();
    this.advanceFiltersItems = [{ key: "", operator: "", value: "" }];
    this.selectedRange = "custom";
    this.showInlineCalander = false;
    this.editedFilter = null;
  }

  resetFilters() {
    this.resetEvent.emit();
    this._filter = null;
    if (this.heading != HEADING.SELECTED) this.applyFilters.emit([]);
    this.heading = HEADING.SELECTED;
    this.formReset();
    this.disableApplyORSaveEvent.emit(true);
  }

  handleChangeFilter(chip: { key: string; value: string; operation: string }) {
    let remainingFilters = {
      ...this._filter,
      filterValues: this._filter?.filterValues?.filter(
        (sf) =>
          sf.key !== chip.key ||
          sf.value !== chip.value ||
          sf.operation !== chip.operation
      ),
    };
    this.formReset();
    if (
      remainingFilters?.filterValues?.length &&
      remainingFilters.filterValues.length > 0
    ) {
      this._filter = {
        id: remainingFilters.id,
        filterValues: remainingFilters.filterValues,
        name: remainingFilters.name,
      };
      this.initializeFilterForm({
        filterValues: remainingFilters.filterValues,
      });
      this.applyFilters.emit(this._filter?.filterValues);
    } else {
      this.resetFilters();
    }
  }

  initializeFilterForm(filter: SavedFilter) {
    filter.filterValues.forEach((filterItem) => {
      switch (filterItem.key) {
        case filterKeys.EVENT_DATE:
          if (filterItem.multi) {
            const eventDates = filterItem?.value?.split(",");
            this.filterForm.patchValue({
              eventDateRange: eventDates.map((ed) => new Date(ed)),
              multiEventDate: true,
              eventDateOperation: filterItem.operation,
            });
            this.eventDateRange?.enable();
            this.eventDate?.disable();
            this.eventDateOperation?.disable();
          } else {
            this.filterForm.patchValue({
              eventDate: filterItem.value,
              multiEventDate: false,
              eventDateOperation: filterItem.operation,
            });
            this.eventDate?.enable();
            this.eventDateOperation?.enable();
            this.eventDateRange?.disable();
          }
          break;

        case filterKeys.GENDER:
          const genders = filterItem?.value?.split(",").map((value) => ({
            value: value.trim(),
            checked: true,
          }));
          this.filterForm.patchValue({
            gender: genders,
          });
          break;

        case filterKeys.AGE:
          if (filterItem?.multi) {
            const ageValues = filterItem.value?.split(",");
            this.filterForm.patchValue({
              isAgeRange: true,
              ageRange: [Number(ageValues[0]), Number(ageValues[1])],
              ageOperation: { name: "BETWEEN", operation: "BETWEEN" },
            });
            this.handleAgeToggle();
          } else {
            this.filterForm.patchValue({
              isAgeRange: false,
              age: Number(filterItem.value),
              ageOperation: {
                name: filterItem.operation,
                operation: filterItem.operation,
              },
            });
            this.handleAgeToggle();
          }
          break;

        case filterKeys.PATIENT_CLASS:
          this.filterForm.patchValue({
            patientClass: filterItem?.value?.split(",").map((value) => {
              return {
                value: value.trim(),
                checked: true,
              };
            }),
          });
          break;

        case filterKeys.EVENT:
          this.filterForm.patchValue({
            event: filterItem.value.split(",").map((value) => {
              return {
                value: value.trim(),
                checked: true,
              };
            }),
          });

          break;

        case filterKeys.FACILITY:
          this.filterForm.patchValue({
            facility: filterItem.value.split(",").map((value) => {
              return { value: value.trim() };
            }),
          });
          break;

        case filterKeys.ADMIT_REASON:
        case filterKeys.DISCHARGE_DISPOSITION:
        case filterKeys.DX_CODES:
        case filterKeys.PCB_NAME:
          this.updateAdvancedFilters(filterItem);
          break;
      }
    });
  }

  updateAdvancedFilters(filterItem: Filter) {
    this.advanceFiltersItems.push({
      key: filterItem.key,
      operator: filterItem.operation,
      value: filterItem.value,
    });
    this.advanceFiltersItems = this.advanceFiltersItems.filter(
      (af) => af.key != ""
    );
    if (this._filter) {
      this._filter.filterValues = this.mapFormToFilter();
    } else {
      this._filter = { filterValues: this.mapFormToFilter() };
    }
  }

  formatEventDate(date: Date) {
    return this.datePipe.transform(date, "MM/dd/yyyy");
  }

  calculateEventDateValue() {
    if (
      this.multiEventDate?.value &&
      this.eventDateRange?.value &&
      !this.eventDateRange?.value?.includes(null)
    ) {
      let arr = this.eventDateRange?.value?.map((edr: Date) =>
        this.formatEventDate(edr)
      );
      return arr.toString();
    } else if (!this.multiEventDate?.value) {
      return this.formatEventDate(this.eventDate?.value);
    } else {
      return null;
    }
  }

  mapAgeValue() {
    if (this.isAgeRange?.value) {
      return this.ageRange?.value.toString();
    } else if (this.age?.value && this.age.valid) {
      return this.age?.value + "";
    } else {
      return null;
    }
  }

  mapFormToFilter(): Filter[] {
    let previusFilterState = this._filter?.filterValues;
    let newFilterState = [
      {
        key: filterKeys.EVENT_DATE,
        value: this.calculateEventDateValue(),
        operation: this.multiEventDate?.value
          ? "BETWEEN"
          : this.eventDateOperation?.value,
        multi: this.multiEventDate?.value,
      },
      {
        key: filterKeys.GENDER,
        value: this.gender?.value
          ?.map((g: GenderCheckBox) => g.value)
          .toString(),
        operation: this.gender?.value?.length > 1 ? "IN" : "EQUALS",
        multi: this.gender?.value?.length > 1,
      },
      {
        key: filterKeys.AGE,
        value: this.mapAgeValue(),
        operation: this.isAgeRange?.value
          ? "BETWEEN"
          : this.ageOperation?.value?.name,
        multi: this.isAgeRange?.value,
      },
      {
        key: filterKeys.PATIENT_CLASS,
        value: this.patientClass?.value
          ?.map((sc: PatientClassCheckBox) => sc.value)
          .toString(),
        operation: this.patientClass?.value?.length > 1 ? "IN" : "EQUALS",
        multi: this.patientClass?.value?.length > 1,
      },
      {
        key: filterKeys.EVENT,
        value: this.event?.value
          ?.map((sc: EventCheckBox) => sc.value)
          .toString(),
        operation: this.event?.value?.length > 1 ? "IN" : "EQUALS",
        multi: this.patientClass?.value?.length > 1,
      },
      {
        key: filterKeys.FACILITY,
        value: this.facility?.value?.map((sc: Facility) => sc.value).toString(),
        operation: this.facility?.value?.length > 1 ? "IN" : "EQUALS",
        multi: this.facility?.value?.length > 1,
      },
      ...this.advanceFiltersItems.map((af) => {
        return {
          key: af.key,
          value: af.value,
          operation: af.operator,
          multi: false,
        };
      }),
    ].filter(
      (f) =>
        f.value !== undefined &&
        f.value !== null &&
        f.value !== "" &&
        f.operation !== undefined &&
        f.operation !== null &&
        f.operation !== ""
    );
    if (
      JSON.stringify(newFilterState) !== JSON.stringify(previusFilterState) &&
      this.heading == HEADING.APPLIED
    ) {
      this.applyFilters.emit(this._filter?.filterValues);
    }
    if (newFilterState.length > 0) {
      this.disableApplyORSaveEvent.emit(false);
    } else {
      this.disableApplyORSaveEvent.emit(true);
      if (this.heading == HEADING.APPLIED) this.applyFilters.emit([]);
    }
    return newFilterState;
  }

  closeSidebar() {
    if (this.heading !== HEADING.APPLIED) {
      this.formReset();
    }
    this.closeSidebarEvent.emit();
  }
}
