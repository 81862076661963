import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KeyMappingService {
  private jsonFilePath = 'assets/key-mapping.json'; // Path to the JSON file

  constructor(private http: HttpClient) {}

  // Method to fetch the key-value mapping from the JSON file
  getKeyMapping(): Observable<{ [key: string]: string }> {
    return this.http.get<{ [key: string]: string }>(this.jsonFilePath);
  }
}
