<div class="flex align-items-center gap-2">
  <!-- <p-checkbox
    [(ngModel)]="checkbox.checked"
    [binary]="true"
    (onChange)="onCheckboxChange()"
    [label]="checkbox.label"
    [ngClass]="pCheckboxStyleClass"
    class=""
  ></p-checkbox> -->
  <div *ngFor="let checkbox of checkboxes" class="genderCheckbox">
    <input type='checkbox' id='{{checkbox.label}}' class=' {{checkbox.label}}' name='checkbox' [(ngModel)]="checkbox.checked" (change)="onCheckboxChange()">
    <label for='{{checkbox.label}}'><img src="/assets/{{checkbox.label}}.svg" alt=""> {{checkbox.label}}</label>
  </div>

</div>