<div class="filter-section">
  <div class="max-appliedFilter">
    <div class="flex gap-2 align-items-center">
      <h3 class="appliedFilter-heading">{{heading| keyFormatter}} Filters</h3>  
      <p-button *ngIf="_filter?.filterValues?.length" label="Clear {{heading| keyFormatter}} Filters" styleClass="max-filter-actBtn" (onClick)="resetFilters()"/>
    </div>
    <div *ngIf="!_filter || _filter?.filterValues?.length==0" class="text-center">
      <img src="/assets/noapplied-Filter.svg" alt="">
      <p>No filters applied yet.</p>
    </div>  
    <app-chips-list [chips]="_filter?.filterValues??[]" [removable]="true" (removedChip)="handleChangeFilter($event)" ></app-chips-list>
  </div>
</div>

<!-- FORM -->
<form class="form" [formGroup]="filterForm">
  <p-accordion #accordion [multiple]="true" [(activeIndex)]="filterActiveIndexes">
    <p-accordionTab  >
      <ng-template pTemplate="header" >
        <div class="mxn-Facc-title">Event Date</div>
      </ng-template>
      <ng-template pTemplate="content" >
        <div class="flex align-items-center gap-2 mb-3" >
          <p-radioButton
            formControlName="multiEventDate" 
            [value]="false"
            name="multiEventDate"
            (onClick)="handleEventDateToggle(false)"
          />
          <p-calendar formControlName="eventDate" [showClear]="true" [placeholder]="eventDate?.value ? eventDate?.value: 'Select date'" [readonlyInput]="true"/>
          <label for="eventDateOperation">Time Interval</label>
          <app-combo-box
            [items]="eventDateTimeIntervalList"
            [placeholder]="eventDateOperation?.value ?? 'Select'"
            [width]="'100%'"
            formControlName="eventDateOperation"
            (selectionChange)="handleEventDateOperationChange($event)"
            >
          </app-combo-box>
        </div>

        <div class="flex align-items-center gap-2">
          <p-radioButton
            formControlName="multiEventDate"
            [value]="true"
            name="multiEventDate"
            (onClick)="handleEventDateToggle(true)"
          />
          <div class="flex align-items-center gap-2" for="multiple">
            <label class="w-7rem flex-shrink-0">Date Range:</label>
            <p-calendar selectionMode="range" formControlName="eventDateRange" [readonlyInput]="true" [showOnFocus]="false" [showClear]="true" placeholder="Start date - End date" (onFocus)="showInlineCalander=true" (onClear)="handleEventDataRangeClear()"></p-calendar>
          </div>
        </div>
        <div *ngIf="showInlineCalander" class="flex align-items-start gap-2 calendar-container">
          <div class="calendar-sidebar p-2">
            <p-button type="button" label="Last Week" (onClick)="setLastWeek()" styleClass="mxn-clndar-btn" 
            [styleClass]="selectedRange === 'week' ? 'mxn-clndar-btn active' : 'mxn-clndar-btn'"></p-button>
            <p-button type="button" label="Last Month" (onClick)="setLastMonth()" styleClass="mxn-clndar-btn"
            [styleClass]="selectedRange === 'month' ? 'mxn-clndar-btn active' : 'mxn-clndar-btn'"></p-button>
            <p-button type="button" label="Custom" (onClick)="setCustomRange()" styleClass="mxn-clndar-btn"
            [styleClass]="selectedRange === 'custom' ? 'mxn-clndar-btn active' : 'mxn-clndar-btn'"></p-button>
          </div>
          <div class="calendar-content">
            <p-calendar formControlName="eventDateRange" selectionMode="range" [inline]="true" [readonlyInput]="true" (onSelect)="handleEventDataRangeChange()"></p-calendar>
          </div>
        </div>
      </ng-template>
    </p-accordionTab>
  <!-- </p-accordion> -->

  <!-- Gender -->
  <!-- <p-accordion class="w-full" [multiple]="true"> -->
    <p-accordionTab>
      <ng-template pTemplate="header" >
        <div>Gender</div>
      </ng-template>
      <ng-template pTemplate="content" >
      <div>
        <app-chekbox-icon
          [checkboxes]="genderFilterList"
          formControlName="gender"
          [styleClass]="'custom-style-class'"
          [pCheckboxStyleClass]="'p-checkbox-class'"
          (checkboxChange)="handleGenderFilterChange($event)"
        >
        </app-chekbox-icon>
      </div>
    </ng-template>
    </p-accordionTab>
  <!-- </p-accordion> -->

  <!-- Age -->
  <!-- <p-accordion class="w-full" [multiple]="true"> -->
    <p-accordionTab>
      <ng-template pTemplate="header">
        <div>Age</div>
      </ng-template>
      <ng-template pTemplate="content" >
      <div>
        <div class="py-2 flex align-items-center gap-2">
          <label class="flex align-items-center">
            <p-radioButton 
              formControlName="isAgeRange"
              [value]="true"
              name="isAgeRange"
              (onClick)="handleAgeToggle()"
            />
          </label>
          Age Range
        </div>
        <div class="pt-3 w-18rem" >
          <ngx-slider formControlName="ageRange" [(highValue)]="highValue" [options]="options" (valueChange)="validateRange($event)"></ngx-slider>
        </div>

        <div class="py-2 flex align-items-center gap-2 mt-3">
          <label class="flex align-items-center gap-2">
            <p-radioButton
              formControlName="isAgeRange"
              [value]="false"
              name="isAgeRange"
              (onClick)="handleAgeToggle()"
            />
            Age Input
          </label>
          <div class="flex gap-4">
            <!-- <app-combo-box
              [items]="operatorList"
              [optionLabel]="'name'"
              placeholder="Choose an item"
              [width]="'250px'"
              [showSelected]="true"
              [setSelected]="filterForm.get('ageOperation')?.value"
              [disabled]="isAgeDisabled()"
              (selectionChange)="handleAgeOperationChange($event)"
            >
            </app-combo-box>
             -->
            <app-combo-box
             [items]="operatorList"
             [optionLabel]="'name'"
             [placeholder]="!this.isAgeRange?.value && this.ageOperation?.value?.name || 'Select'"
             [width]="'150px'"
             formControlName="ageOperation"
             name="ageOperation"
             (selectionChange)="handleAgeOperationChange($event)"
             ></app-combo-box>
            <div class="flex align-items-center gap-2">
              <label>Value</label>
              <p-inputNumber 
              formControlName="age"
              mode="decimal" 
              inputId="withoutgrouping" 
              [useGrouping]="false"
              [min]="0"
              styleClass="w-5rem" 
              (onInput)="handleAgeInput($event)"
              />
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    </p-accordionTab>
  <!-- </p-accordion> -->
  <!-- Patient Class -->
  <!-- <p-accordion class="w-full" [multiple]="true"> -->
    <p-accordionTab>
      <ng-template pTemplate="header">
        <div>Patient Class</div>
      </ng-template>
      <ng-template pTemplate="content" >
      <div>
        <app-check-box
          [checkboxes]="patientClassList"
          formControlName="patientClass"
          name="patientClass"
          (checkboxChange)="handlePatientClassChange($event)"
        >
        </app-check-box>
      </div>
    </ng-template>
    </p-accordionTab>
  <!-- </p-accordion> -->
  <!-- Event -->
  <!-- <p-accordion class="w-full" [multiple]="true"> -->
    <p-accordionTab>
      <ng-template pTemplate="header">
        <div>Event</div>
      </ng-template>
      <ng-template pTemplate="content" >
      <div>
        <app-check-box
          [checkboxes]="eventList"
          formControlName="event"
          name="event"
          (checkboxChange)="handleEventChange($event)"
        >
        </app-check-box>
      </div>
      </ng-template>
    </p-accordionTab>
  <!-- </p-accordion> -->

  <!-- Facility -->
  <!-- <p-accordion class="w-full" [multiple]="true"> -->
    <p-accordionTab>
      <ng-template pTemplate="header">
        <div>Facility</div>
      </ng-template>
      <ng-template pTemplate="content" >
      <div>
        <div *ngIf="facility?.value?.length" class="facilityChipList">
          <app-chips-list
            [chips]="facility?.value"
            [removable]="true"
            [showKey]="false"
            (chipsChange)="handleFacilityChipListChange($event)"
          ></app-chips-list>
        </div>
        <div class="w-full facilityComboBox">
          <app-combo-box
          [items]="facilityList"
          [optionLabel]="'name'"
          placeholder="Search and select facility"
          [width]="'100%'"
          [filter]="true"
          styleClass=""
          [showSelected]="false"
          dropdownIcon="pi pi-search"
          (selectionChange)="handleFacilityChange($event)"
        >
        </app-combo-box>
        </div>
        
      </div>
    </ng-template>
    </p-accordionTab>
  <!-- </p-accordion> -->

  <!-- Advanced -->
  <!-- <p-accordion class="w-full" [multiple]="true"> -->
    <p-accordionTab>
      <ng-template pTemplate="header">
        <div>Advanced Filters</div>
      </ng-template>
      <ng-template pTemplate="content" >
      <div class="mxn-advanceFiltersrow">
        <div *ngFor="let item of advanceFiltersItems; let i = index" class="flex flex-wrap py-2 align-items-center border-top-1 border-gray">
          <div class="col-11 px-0">
            <app-combo-box
              [items]="advanceItems"
              [placeholder]="advanceFiltersItems[i].key!=''? (advanceFiltersItems[i].key| keyFormatter): 'Select'"
              [width]="'100%'"
              (selectionChange)="handleAdvanceItemChange($event, i)">
              </app-combo-box>
          </div>
          <div class="col-1 pr-0">
            <button class="bg-transparent border-none cursor-pointer" (click)="handleRemoveAdvancedFilters(i)"><img src="/assets/trash.svg" alt=""></button>
          </div>
          <div class="col-3 pl-0">
            <!-- <app-combo-box
            [items]="advanceItems"
            [width]="'100%'"
            (selectionChange)="handleAdvanceItemChange($event, i)">
            </app-combo-box> -->
            <app-combo-box
            [items]="signOperator"
            [width]="'100%'"
            [placeholder]="advanceFiltersItems[i].operator!=''? advanceFiltersItems[i].operator:'Select'"
            (selectionChange)="handleAdvanceOperatorListChange($event, i)">
            </app-combo-box>
          </div>
          <div class="col-8 pr-0">
            <app-combo-box
              [items]="advanceFiltersValueList"
              [optionLabel]="'name'"
              [placeholder]="advanceFiltersItems[i].value!=''? advanceFiltersItems[i].value:'Search & select'"
              [width]="'100%'"
              [filter]="true"
              [showClear]="true"
              styleClass=""
              dropdownIcon="pi pi-search"
              (selectionChange)="handleAdvanceValueChange($event, i)"
            >
            </app-combo-box>
          </div>
          <div class="col-1 pr-0" *ngIf="advanceFiltersItems.length-1 == i && advanceFiltersItems.length!==5">
            <button [disabled]="isAdvanceFilterAddButtonDisabled(i)" class="mxn-addComboBox-btn disabled" (click)="addComboBox()"> <!-- Add Button -->
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M7.88176 7.93848L12.8202 7.93848C13.0513 7.93394 13.2714 7.83898 13.4332 7.67399C13.5951 7.509 13.6858 7.28712 13.6859 7.056C13.686 6.82488 13.5954 6.60293 13.4337 6.43781C13.272 6.27269 13.052 6.17755 12.8209 6.17283L7.88388 6.17283L7.88388 1.23581C7.88012 1.00388 7.78534 0.782716 7.61999 0.620028C7.45464 0.457341 7.23197 0.366165 7 0.366165C6.76803 0.366165 6.54536 0.457341 6.38001 0.620028C6.21466 0.782716 6.11988 1.00388 6.11612 1.23581L6.11612 6.17283L1.1791 6.17283C0.947162 6.1766 0.726001 6.27138 0.563314 6.43673C0.400626 6.60208 0.30945 6.82475 0.30945 7.05671C0.30945 7.28868 0.400626 7.51135 0.563313 7.6767C0.726 7.84205 0.947162 7.93683 1.1791 7.9406L6.11612 7.9406V12.8776C6.11421 12.9949 6.13567 13.1114 6.17923 13.2203C6.2228 13.3292 6.2876 13.4283 6.36986 13.512C6.45213 13.5956 6.55021 13.662 6.6584 13.7073C6.76658 13.7526 6.8827 13.776 7 13.776C7.1173 13.776 7.23342 13.7526 7.3416 13.7073C7.44979 13.662 7.54787 13.5956 7.63014 13.512C7.7124 13.4283 7.7772 13.3292 7.82077 13.2203C7.86433 13.1114 7.88579 12.9949 7.88388 12.8776L7.88176 7.93848Z" fill=""/>
              </svg>
            </button>
          </div>
        </div>
        <div class="mxn-advanceFilters-notes">
          <img src="/assets/info-icon.svg" alt="">
          A maximum of 5 can be selected.
        </div>
      </div>
    </ng-template>
    </p-accordionTab>
  </p-accordion>
</form>
