import { Component, EventEmitter, Input, Output } from "@angular/core";
import { StateService } from "../../../services/state.service";
import { CommentService } from "../../../services/notification/comment.service";
import { IComment } from "../../../models/comment.model";

@Component({
  selector: "mxn-comment-card",
  templateUrl: "./comment-card.component.html",
  styleUrl: "./comment-card.component.scss",
})
export class CommentCardComponent {
  constructor(
    private stateService: StateService,
    private commentService: CommentService
  ) {}
  @Input() notificationId: string = "";
  @Input() comment: IComment = {} as IComment;
  @Input() startedEditing: boolean = false;
  isDeletable: boolean = true;
  isEditable: boolean = true;

  @Output() onClickEditComment = new EventEmitter<any>();
  @Output() toggleEditComment = new EventEmitter<any>();
  @Output() onClickDeleteComment = new EventEmitter<any>();
  @Output() refreshNotificationDetail = new EventEmitter<any>();

  isEditing: boolean = false;
  commentValue: string = "";
  commentedBy: string = "";

  ngOnInit(): void {
    this.isEditing = false;
    this.commentValue = this.comment.text;
    this.commentedBy = `${this.comment.user?.first_name ?? ""} ${
      this.comment.user?.last_name ?? ""
    }`;
  }

  toggleEditView() {
    this.isEditing = true;
    this.toggleEditComment.emit({
      id: this.comment.id,
    });
  }

  onChangeComment(event: any) {
    this.commentValue = event.target.value;
  }
  onSave() {
    this.onClickEditComment.emit({
      id: this.comment.id,
      text: this.commentValue,
      heading: this.comment.heading,
      user: this.comment.user,
    });
    this.commentService
      .updateComment(this.comment.id, {
        text: this.commentValue,
        heading: this.comment.heading,
      })
      .subscribe((res) => {
        this.refreshNotificationDetail.emit();
      });
    this.isEditing = false;
  }

  onDelete() {
    this.onClickDeleteComment.emit({
      id: this.comment.id,
    });
  }
}
